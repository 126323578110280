import React, { useState } from "react";
import { nodesFromCollectionQuery } from "../../../helper/nodesFromCollectionQuery";
import {
  useSensorCommentsQuery,
  SensorCommentDataFragment,
  SensorDetailFragment,
} from "../../../graphql/Sensor.generated";
import { PaginationApollo } from "../../../component/PaginationApollo";
import { SensorCommentList } from "../component/SensorCommentList";
import { AddCommentForm } from "../component/AddCommentForm";
import { gql } from "@apollo/client";
import { useAddSensorCommentMutation } from "./SensorCommentsContainer.generated";
import { UserDataFragment } from "../../../graphql/User.generated";
import { Button } from "../../../component/Button";

export const ADD_SENSOR_COMMENT = gql`
  mutation addSensorComment(
    $sensorId: String
    $userId: String
    $text: String!
  ) {
    createSensorComment(
      input: { sensor: $sensorId, user: $userId, text: $text }
    ) {
      sensorComment {
        id
      }
    }
  }
`;

interface Props {
  sensor: SensorDetailFragment;
  user: UserDataFragment;
}

export const SensorCommentsContainer: React.FunctionComponent<Props> = ({
  sensor,
  user,
}) => {
  const [showAddCommentForm, setShowAddCommentForm] = useState(false);
  const pageSize = 5;
  const { data, fetchMore, refetch } = useSensorCommentsQuery({
    variables: { deviceName: sensor.deviceName, pageSize },
  });
  const [addSensorComment] = useAddSensorCommentMutation();

  const sensorComments = nodesFromCollectionQuery<SensorCommentDataFragment>(
    data?.sensorComments
  );

  const addComment = (text: string) => {
    addSensorComment({
      variables: { sensorId: sensor.id, userId: user.id, text },
    }).finally(refetch);
  };

  return (
    <div>
      <div className="flex px-4 py-5">
        <h3 className="flex-grow text-lg leading-6 font-medium text-gray-900">
          Comments
        </h3>
        {!showAddCommentForm && (
          <Button
            onClick={(event) => {
              event.preventDefault();
              setShowAddCommentForm(true);
            }}
          >
            Add Comment
          </Button>
        )}
      </div>
      {showAddCommentForm && (
        <div className="mx-4 my-5 border-b border-gray-200">
          <AddCommentForm
            onCancel={() => setShowAddCommentForm(false)}
            onAddComment={(text) => {
              addComment(text);
              setShowAddCommentForm(false);
            }}
          ></AddCommentForm>
        </div>
      )}
      <SensorCommentList sensorComments={sensorComments}></SensorCommentList>
      <div className="w-full px-4">
        <PaginationApollo
          pageInfo={data?.sensorComments?.pageInfo}
          fetchMore={fetchMore}
        >
          <span className="text-xs xs:text-sm text-gray-900 float-right py-5 pr-4">
            Total of {data?.sensorComments?.totalCount.toLocaleString()}{" "}
            comments
          </span>
        </PaginationApollo>
      </div>
    </div>
  );
};
