import { gql } from "@apollo/client";
import React, { useEffect } from "react";
import { LiveReloadIndicator } from "../../../component/LiveReloadIndicator";
import { PageSizeSelectorWithFilter } from "../../../component/PageSizeSelectorWithFilter";
import {
  PageVariables,
  PaginationApollo,
} from "../../../component/PaginationApollo";
import { SensorFilter_Order } from "../../../generated/graphql";
import { SensorsQueryVariables } from "../hook/useSensors.generated";
import {
  useNumberUrlState,
  useObjectUrlState,
  useUrlState,
} from "../../../hook/useUrlState";
import { useToggle } from "../../../hook/useToggle";
import { SensorApprovalTable } from "./component/SensorApprovalTable";
import {
  UnapprovedSensorFragment,
  useApproveAllSensorsMutation,
  useApproveSensorMutation,
  useUnapprovedSensorsQuery,
} from "./SensorApprovalScene.generated";
import { NoSensorsToApprovePlaceholder } from "./component/NoSensorsToApprovePlaceholder";
import { nodesFromCollectionQuery } from "../../../helper/nodesFromCollectionQuery";

export const APPROVE_SENSOR = gql`
  mutation approveSensor($id: ID!, $approved: Boolean) {
    updateSensor(input: { id: $id, approved: $approved }) {
      sensor {
        id
        approved
      }
    }
  }
`;

export const APPROVE_ALL_SENSORS = gql`
  mutation approveAllSensors {
    approveAllSensor(input: {}) {
      clientMutationId
    }
  }
`;

export const GET_UNAPPROVED_SENSORS = gql`
  fragment UnapprovedSensor on Sensor {
    id
    deviceName
    vpnStatus {
      publicAddress
    }
    status {
      modelType
      macAddress
    }
  }
  query UnapprovedSensors(
    $order: SensorFilter_order
    $search: String
    $pageSize: Int!
    $after: String
    $before: String
  ) {
    sensors(
      search: $search
      order: $order
      first: $pageSize
      after: $after
      before: $before
      approved: false
    ) @connection(key: "unapproved_sensors", filters: ["search", "pageSize"]) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...UnapprovedSensor
        }
      }
    }
  }
`;

export const SensorApprovalScene: React.FunctionComponent = () => {
  const [poll, togglePoll] = useToggle(true);
  const [pageVariables, setPageVariables] = useObjectUrlState<PageVariables>(
    "pageInfo",
    {}
  );
  const [filter, setFilter] = useUrlState("search", "");
  const [sortFieldValueFromUrl, setSortField] = useUrlState("sortField", "");

  let sortField: keyof SensorFilter_Order | null = null;
  if (sortFieldValueFromUrl !== "") {
    sortField = sortFieldValueFromUrl as keyof SensorFilter_Order;
  }
  const [sortDirection, setSortDirection] = useUrlState("sortDirection", "asc");
  const [pageSize, setPageSize] = useNumberUrlState("pageSize", 25);
  const queryVariables: SensorsQueryVariables = {
    search: filter,
    pageSize,
    order: undefined,
  };

  if (sortField) {
    queryVariables.order = {};
    queryVariables.order[sortField] = sortDirection;
  }

  const {
    loading,
    error,
    data,
    fetchMore,
    refetch,
  } = useUnapprovedSensorsQuery({
    pollInterval: 10_000,
    variables: {
      ...queryVariables,
      ...pageVariables,
      search: filter,
      order: sortField ? { [sortField]: sortDirection } : undefined,
    },
  });

  // Force refetch on render to avoid delay in seeing fresh results when switching to this scene
  useEffect(() => {
    refetch();
  }, [refetch]);

  const sensors: UnapprovedSensorFragment[] = nodesFromCollectionQuery(
    data?.sensors
  );

  const [approveAllSensors] = useApproveAllSensorsMutation({
    refetchQueries: ["Sensors", "UnapprovedSensors", "UnapprovedSensorsCount"],
  });
  const [approveSensor] = useApproveSensorMutation({
    refetchQueries: ["Sensors", "UnapprovedSensors", "UnapprovedSensorsCount"],
  });

  if (error) return <p>Error :(</p>;

  const shouldDisplayTable = sensors.length > 0 || filter !== "";

  if (!shouldDisplayTable) {
    return <NoSensorsToApprovePlaceholder />;
  }

  return (
    <div className="flex flex-col max-h-full container mx-auto px-4 sm:px-8">
      <div className="flex-none py-4">
        <div className="flex items-center">
          <h2 className="text-2xl font-semibold leading-tight mr-4">
            Sensor Approval
          </h2>
          <LiveReloadIndicator isPolling={poll} onClick={togglePoll} />
        </div>
        {shouldDisplayTable && (
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center">
              <PageSizeSelectorWithFilter
                filter={filter}
                onFilterChange={setFilter}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </div>
          </div>
        )}
      </div>
      <SensorApprovalTable
        sensors={sensors}
        loading={loading}
        onSortChanged={(field, direction) => {
          setSortField(field);
          setSortDirection(direction);
        }}
        onApprove={(sensorId) =>
          approveSensor({
            variables: {
              id: sensorId,
              approved: true,
            },
          })
        }
        onApproveAll={approveAllSensors}
        footer={
          <div className="w-full h-12 px-4">
            <PaginationApollo
              pageInfo={data?.sensors?.pageInfo}
              fetchMore={fetchMore}
              onPageChange={setPageVariables}
            >
              <span className="text-xs xs:text-sm text-gray-900 float-right py-5 pr-4">
                Total of {data?.sensors?.totalCount.toLocaleString()} sensors
              </span>
            </PaginationApollo>
          </div>
        }
      />
    </div>
  );
};
