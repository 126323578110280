import { useDragLayer as useReactDndDragLayer } from "react-dnd";
import { DragLayerMonitor } from "react-dnd/lib/interfaces";
import { DragObject } from "./useDrag";

type GenericDragLayerMonitor<ItemType> = Omit<DragLayerMonitor, "getItem"> & {
  getItem: () => ItemType;
};

export const useDragLayer = <CollectedProps>(
  collect: (monitor: GenericDragLayerMonitor<DragObject>) => CollectedProps
) => useReactDndDragLayer(collect);
