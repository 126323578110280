import { useHistory, useLocation } from "react-router";

type StateValue = string;

export function useUrlState(
  queryParameterName: string,
  initialState: StateValue,
  historyPushInsteadOfReplace: boolean = false
): [StateValue, (newValue: StateValue) => void] {
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const value = urlSearchParams.get(queryParameterName) ?? initialState;

  const setValue = (newValue: StateValue) => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const value = urlSearchParams.get(queryParameterName) ?? initialState;
    if (newValue === value) {
      return;
    }
    if (newValue === initialState) {
      urlSearchParams.delete(queryParameterName);
    } else {
      urlSearchParams.set(queryParameterName, newValue);
    }

    const newSearch = urlSearchParams.toString();
    if (historyPushInsteadOfReplace) {
      history.push(`${location.pathname}?${newSearch}${location.hash}`);
    } else {
      history.replace(`${location.pathname}?${newSearch}${location.hash}`);
    }
  };
  return [value, setValue];
}

export function useBooleanUrlState(
  queryParameterName: string,
  initialValue: boolean = false,
  historyPushInsteadOfReplace: boolean = false
): [boolean, (newValue: boolean) => void] {
  const [value, setValue] = useUrlState(
    queryParameterName,
    String(initialValue),
    historyPushInsteadOfReplace
  );

  return [
    value === "true",
    (newValue) => {
      setValue(String(newValue));
    },
  ];
}

export function useNumberUrlState(
  queryParameterName: string,
  initialValue: number,
  historyPushInsteadOfReplace: boolean = false
): [number, (newValue: number) => void] {
  const [value, setValue] = useUrlState(
    queryParameterName,
    String(initialValue),
    historyPushInsteadOfReplace
  );

  return [
    Number(value),
    (newValue) => {
      setValue(String(newValue));
    },
  ];
}

export function useObjectUrlState<T>(
  queryParameterName: string,
  initialValue: T,
  historyPushInsteadOfReplace: boolean = false
): [T, (newValue: T) => void] {
  const [value, setValue] = useUrlState(
    queryParameterName,
    JSON.stringify(initialValue),
    historyPushInsteadOfReplace
  );

  return [
    JSON.parse(value) as T,
    (newValue) => {
      setValue(JSON.stringify(newValue));
    },
  ];
}
