import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  // Howdy, wanderer. You might want to enable strict mode here.
  // Please be aware that this caused issues in the past.
  // One issue was that the Apollo Client detected every twice,
  // which caused issues when a mutation caused a reload of a Query.
  <App />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
