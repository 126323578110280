import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownload,
  faSignal,
  faSignalSlash,
  faStar,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faStar as faStarRegular,
  faStickyNote,
  faTools,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { MultiSelectActionWidget } from "./MultiSelectActionWidget";

interface Props {
  selectionCount: number;
  onEnableVpn: () => void;
  onDisableVpn: () => void;
  onComment: () => void;
  onSetSensorsFavorited: () => void;
  onSetSensorsUnfavorited: () => void;
  onDelete: () => void;
  onDownloadImageData: () => void;
  onDownloadDiagnosticData: () => void;
  onTriggerUpdate: () => void;
}

export const SensorCollectionActions: React.FunctionComponent<Props> = ({
  selectionCount,
  onEnableVpn,
  onDisableVpn,
  onComment,
  onSetSensorsFavorited,
  onSetSensorsUnfavorited,
  onDelete,
  onDownloadImageData,
  onDownloadDiagnosticData,
  onTriggerUpdate,
}) => {
  return (
    <MultiSelectActionWidget selectionCount={selectionCount}>
      <div className="mt-2 w-56 rounded-md shadow-lg">
        <div
          className="rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
        >
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={() => {
              onEnableVpn();
            }}
          >
            <FontAwesomeIcon
              className="text-tradewind-500"
              style={{ width: "20" }}
              icon={faSignal}
            />
            <span className="ml-2">Enable VPN</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={(event) => {
              onDisableVpn();
            }}
          >
            <FontAwesomeIcon
              className="text-red-500"
              style={{ width: "20" }}
              icon={faSignalSlash}
            />
            <span className="ml-2">Disable VPN</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={(event) => {
              onComment();
            }}
          >
            <FontAwesomeIcon style={{ width: "20" }} icon={faStickyNote} />
            <span className="ml-2">Comment</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={(event) => {
              onDownloadImageData();
            }}
          >
            <FontAwesomeIcon style={{ width: "20" }} icon={faCloudDownload} />
            <span className="ml-2">Download Image Data</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={(event) => {
              onDownloadDiagnosticData();
            }}
          >
            <FontAwesomeIcon style={{ width: "20" }} icon={faCloudDownload} />
            <span className="ml-2">Download Diagnostic Data</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={(event) => {
              onTriggerUpdate();
            }}
          >
            <FontAwesomeIcon style={{ width: "20" }} icon={faTools} />
            <span className="ml-2">Update Firmware</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={(event) => {
              onSetSensorsFavorited();
            }}
          >
            <FontAwesomeIcon
              className="text-sunglow-500"
              style={{ width: "20" }}
              icon={faStar}
            />
            <span className="ml-2">Favorite</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            role="menuitem"
            onClick={(event) => {
              onSetSensorsUnfavorited();
            }}
          >
            <FontAwesomeIcon
              className="text-sunglow-500"
              style={{ width: "20" }}
              icon={faStarRegular}
            />
            <span className="ml-2">Unfavorite</span>
          </button>
          <button
            className="block px-4 py-2 text-sm leading-5 text-red-500 w-full text-left hover:bg-red-100 focus:outline-none"
            role="menuitem"
            onClick={(event) => {
              onDelete();
            }}
          >
            <FontAwesomeIcon style={{ width: "20" }} icon={faTrash} />
            <span className="ml-2">Delete</span>
          </button>
        </div>
      </div>
    </MultiSelectActionWidget>
  );
};
