import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SensorRowFragment = (
  { __typename?: 'Sensor' }
  & Pick<Types.Sensor, 'id' | 'deviceName' | 'connected' | 'shouldConnect' | 'type' | 'httpUrl' | 'httpsUrl' | 'sshUrl' | 'favorite' | 'connectionStatus' | 'updateScheduled' | 'updateDeadline' | 'updateName'>
  & { lastConnection?: Types.Maybe<(
    { __typename?: 'ConnectionLogEntry' }
    & Pick<Types.ConnectionLogEntry, 'timestamp'>
  )>, vpnStatus?: Types.Maybe<(
    { __typename?: 'SensorVpnStatus' }
    & Pick<Types.SensorVpnStatus, 'publicAddress' | 'vpnAddress' | 'connectedSince'>
  )>, status?: Types.Maybe<(
    { __typename?: 'SensorStatus' }
    & Pick<Types.SensorStatus, 'macAddress' | 'firmwareVersion' | 'modelType'>
  )> }
);

export type SensorDetailFragment = (
  { __typename?: 'Sensor' }
  & Pick<Types.Sensor, 'id' | 'deviceName' | 'shouldConnect' | 'type' | 'shouldConnectChanged' | 'createdAt' | 'connected' | 'connectionStatus' | 'certificateValidUntil' | 'favorite' | 'updateScheduled' | 'updateDeadline' | 'updateName'>
  & { lastConnection?: Types.Maybe<(
    { __typename?: 'ConnectionLogEntry' }
    & Pick<Types.ConnectionLogEntry, 'timestamp'>
  )>, vpnStatus?: Types.Maybe<(
    { __typename?: 'SensorVpnStatus' }
    & Pick<Types.SensorVpnStatus, 'publicAddress' | 'vpnAddress' | 'connectedSince'>
  )>, status?: Types.Maybe<(
    { __typename?: 'SensorStatus' }
    & Pick<Types.SensorStatus, 'macAddress' | 'firmwareVersion' | 'hardwareType' | 'modelType' | 'licenses'>
    & { hmiConnection?: Types.Maybe<(
      { __typename?: 'SensorHmiConnection' }
      & Pick<Types.SensorHmiConnection, 'protocol' | 'port'>
    )>, timezone?: Types.Maybe<(
      { __typename?: 'SensorTimeZone' }
      & Pick<Types.SensorTimeZone, 'name' | 'utcOffset'>
    )> }
  )>, sensorGroup?: Types.Maybe<(
    { __typename?: 'SensorGroup' }
    & Pick<Types.SensorGroup, 'id' | 'name'>
  )> }
);

export type SensorCommentDataFragment = (
  { __typename?: 'SensorComment' }
  & Pick<Types.SensorComment, 'id' | 'text' | 'timestamp'>
  & { user?: Types.Maybe<(
    { __typename?: 'User' }
    & Pick<Types.User, 'id' | 'username'>
  )> }
);

export type SensorDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SensorDetailQuery = (
  { __typename?: 'Query' }
  & { sensor?: Types.Maybe<(
    { __typename?: 'Sensor' }
    & SensorDetailFragment
  )> }
);

export type SensorCommentsQueryVariables = Types.Exact<{
  deviceName?: Types.Maybe<Types.Scalars['String']>;
  pageSize: Types.Scalars['Int'];
  after?: Types.Maybe<Types.Scalars['String']>;
  before?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SensorCommentsQuery = (
  { __typename?: 'Query' }
  & { sensorComments?: Types.Maybe<(
    { __typename?: 'SensorCommentConnection' }
    & Pick<Types.SensorCommentConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'SensorCommentPageInfo' }
      & Pick<Types.SensorCommentPageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'SensorCommentEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'SensorComment' }
        & SensorCommentDataFragment
      )> }
    )>>> }
  )> }
);

export type FavoriteSensorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  favorite: Types.Scalars['Boolean'];
}>;


export type FavoriteSensorMutation = (
  { __typename?: 'Mutation' }
  & { favoriteSensor?: Types.Maybe<(
    { __typename?: 'favoriteSensorPayload' }
    & { sensor?: Types.Maybe<(
      { __typename?: 'Sensor' }
      & Pick<Types.Sensor, 'id' | 'favorite'>
    )> }
  )> }
);

export const SensorRowFragmentDoc = gql`
    fragment SensorRow on Sensor {
  id
  lastConnection {
    timestamp
  }
  deviceName
  connected
  shouldConnect
  type
  httpUrl
  httpsUrl
  sshUrl
  favorite
  connectionStatus
  updateScheduled
  updateDeadline
  updateName
  vpnStatus {
    publicAddress
    vpnAddress
    connectedSince
  }
  status {
    macAddress
    firmwareVersion
    modelType
  }
}
    `;
export const SensorDetailFragmentDoc = gql`
    fragment SensorDetail on Sensor {
  id
  lastConnection {
    timestamp
  }
  deviceName
  shouldConnect
  type
  shouldConnectChanged
  createdAt
  connected
  connectionStatus
  certificateValidUntil
  favorite
  updateScheduled
  updateDeadline
  updateName
  vpnStatus {
    publicAddress
    vpnAddress
    connectedSince
  }
  status {
    macAddress
    firmwareVersion
    hardwareType
    modelType
    licenses
    hmiConnection {
      protocol
      port
    }
    timezone {
      name
      utcOffset
    }
  }
  sensorGroup {
    id
    name
  }
}
    `;
export const SensorCommentDataFragmentDoc = gql`
    fragment SensorCommentData on SensorComment {
  id
  text
  timestamp
  user {
    id
    username
  }
}
    `;
export const SensorDetailDocument = gql`
    query SensorDetail($id: ID!) {
  sensor(id: $id) {
    ...SensorDetail
  }
}
    ${SensorDetailFragmentDoc}`;

/**
 * __useSensorDetailQuery__
 *
 * To run a query within a React component, call `useSensorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorDetailQuery(baseOptions: Apollo.QueryHookOptions<SensorDetailQuery, SensorDetailQueryVariables>) {
        return Apollo.useQuery<SensorDetailQuery, SensorDetailQueryVariables>(SensorDetailDocument, baseOptions);
      }
export function useSensorDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorDetailQuery, SensorDetailQueryVariables>) {
          return Apollo.useLazyQuery<SensorDetailQuery, SensorDetailQueryVariables>(SensorDetailDocument, baseOptions);
        }
export type SensorDetailQueryHookResult = ReturnType<typeof useSensorDetailQuery>;
export type SensorDetailLazyQueryHookResult = ReturnType<typeof useSensorDetailLazyQuery>;
export type SensorDetailQueryResult = Apollo.QueryResult<SensorDetailQuery, SensorDetailQueryVariables>;
export const SensorCommentsDocument = gql`
    query SensorComments($deviceName: String, $pageSize: Int!, $after: String, $before: String) {
  sensorComments(
    sensor_deviceName: $deviceName
    first: $pageSize
    after: $after
    before: $before
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...SensorCommentData
      }
    }
  }
}
    ${SensorCommentDataFragmentDoc}`;

/**
 * __useSensorCommentsQuery__
 *
 * To run a query within a React component, call `useSensorCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorCommentsQuery({
 *   variables: {
 *      deviceName: // value for 'deviceName'
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSensorCommentsQuery(baseOptions: Apollo.QueryHookOptions<SensorCommentsQuery, SensorCommentsQueryVariables>) {
        return Apollo.useQuery<SensorCommentsQuery, SensorCommentsQueryVariables>(SensorCommentsDocument, baseOptions);
      }
export function useSensorCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorCommentsQuery, SensorCommentsQueryVariables>) {
          return Apollo.useLazyQuery<SensorCommentsQuery, SensorCommentsQueryVariables>(SensorCommentsDocument, baseOptions);
        }
export type SensorCommentsQueryHookResult = ReturnType<typeof useSensorCommentsQuery>;
export type SensorCommentsLazyQueryHookResult = ReturnType<typeof useSensorCommentsLazyQuery>;
export type SensorCommentsQueryResult = Apollo.QueryResult<SensorCommentsQuery, SensorCommentsQueryVariables>;
export const FavoriteSensorDocument = gql`
    mutation favoriteSensor($id: ID!, $favorite: Boolean!) {
  favoriteSensor(input: {id: $id, favorite: $favorite}) {
    sensor {
      id
      favorite
    }
  }
}
    `;
export type FavoriteSensorMutationFn = Apollo.MutationFunction<FavoriteSensorMutation, FavoriteSensorMutationVariables>;

/**
 * __useFavoriteSensorMutation__
 *
 * To run a mutation, you first call `useFavoriteSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteSensorMutation, { data, loading, error }] = useFavoriteSensorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      favorite: // value for 'favorite'
 *   },
 * });
 */
export function useFavoriteSensorMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteSensorMutation, FavoriteSensorMutationVariables>) {
        return Apollo.useMutation<FavoriteSensorMutation, FavoriteSensorMutationVariables>(FavoriteSensorDocument, baseOptions);
      }
export type FavoriteSensorMutationHookResult = ReturnType<typeof useFavoriteSensorMutation>;
export type FavoriteSensorMutationResult = Apollo.MutationResult<FavoriteSensorMutation>;
export type FavoriteSensorMutationOptions = Apollo.BaseMutationOptions<FavoriteSensorMutation, FavoriteSensorMutationVariables>;