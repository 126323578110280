import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type SensorGroupNodeFragment = (
  { __typename?: 'SensorGroup' }
  & Pick<Types.SensorGroup, 'id' | 'name' | 'isExpanded'>
  & { parent?: Types.Maybe<(
    { __typename?: 'SensorGroup' }
    & Pick<Types.SensorGroup, 'id'>
  )>, sensors?: Types.Maybe<(
    { __typename?: 'SensorConnection' }
    & Pick<Types.SensorConnection, 'totalCount'>
  )> }
);

export const SensorGroupNodeFragmentDoc = gql`
    fragment SensorGroupNode on SensorGroup {
  id
  name
  parent {
    id
  }
  sensors {
    totalCount
  }
  isExpanded @client
}
    `;