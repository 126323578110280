import { useDrag as useReactDndDrag } from "react-dnd";
import { DragSourceHookSpec } from "react-dnd/lib/interfaces";
import { DropResult } from "./useDrop";
type SensorGroupDragObject = {
  id: string;
  type: "SENSOR_GROUP";
  sensorGroupId: string;
  sensorGroupName: string;
};

type SensorsDragObject = {
  id: string;
  type: "SENSORS";
  draggedSensorIds: string[];
  primarySensorDeviceName: string;
};

export type DragObject = SensorGroupDragObject | SensorsDragObject;

export const useDrag = (
  spec: DragSourceHookSpec<DragObject, DropResult, DragObject>
) => useReactDndDrag(spec);
