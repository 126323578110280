import React from "react";
import { SensorCommentDataFragment } from "../../../graphql/Sensor.generated";

interface Props {
  sensorComments: SensorCommentDataFragment[];
}

export const SensorCommentList: React.FunctionComponent<Props> = ({
  sensorComments,
}) => {
  return (
    <ul>
      {sensorComments.map((sensorComment) => (
        <li className="p-4" key={sensorComment.id}>
          <span className="">{sensorComment.text}</span>
          <div className="text-right py-4">
            <span className="text-gray-500">
              {"Added by "}
              {sensorComment.user ? sensorComment.user.username : "<removed>"}
            </span>
            <span className="text-gray-500">
              {" at "}
              {new Date(sensorComment.timestamp!).toLocaleString()}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};
