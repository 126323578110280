import React from "react";
import { XYCoord } from "react-dnd";
import { useDragLayer } from "../../../../hook/dragAndDrop/useDragLayer";

interface Props {
  selectionCount: number;
}

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  initialMouseOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset || !initialMouseOffset) {
    return {
      display: "none",
    };
  }

  let { x, y } = currentOffset;
  const grabXOffset = initialMouseOffset.x - initialOffset.x;

  const transform = `translate(${grabXOffset + x}px, ${y}px) translateX(-50%)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const SensorDragLayer: React.FunctionComponent<Props> = ({
  selectionCount,
}) => {
  const {
    item,
    initialOffset,
    currentOffset,
    initialMouseOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    initialMouseOffset: monitor.getInitialClientOffset(),
  }));

  const badgeTransform = `translate(${-15}px, ${-10}px)`;
  const badgeStyles = {
    transform: badgeTransform,
    WebkitTransform: badgeTransform,
  };

  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      {item?.type === "SENSORS" && (
        <div
          className="bg-gray-300 rounded inline-block px-4 py-2 font-mono text-xs"
          style={getItemStyles(
            initialOffset,
            currentOffset,
            initialMouseOffset
          )}
        >
          {item.primarySensorDeviceName}
          {selectionCount > 1 && (
            <span
              className="absolute top-0 left-0 inline-block rounded-full text-white bg-red-400 px-3 py-1"
              style={badgeStyles}
            >
              {selectionCount}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
