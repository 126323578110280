import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateSensorGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  parent?: Types.Maybe<Types.Scalars['String']>;
}>;


export type CreateSensorGroupMutation = (
  { __typename?: 'Mutation' }
  & { createSensorGroup?: Types.Maybe<(
    { __typename?: 'createSensorGroupPayload' }
    & { sensorGroup?: Types.Maybe<(
      { __typename?: 'SensorGroup' }
      & Pick<Types.SensorGroup, 'id' | 'name'>
      & { parent?: Types.Maybe<(
        { __typename?: 'SensorGroup' }
        & Pick<Types.SensorGroup, 'id'>
      )> }
    )> }
  )> }
);

export type UpdateSensorGroupNameMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type UpdateSensorGroupNameMutation = (
  { __typename?: 'Mutation' }
  & { updateASensorGroup?: Types.Maybe<(
    { __typename?: 'updateASensorGroupPayload' }
    & { sensorGroup?: Types.Maybe<(
      { __typename?: 'SensorGroup' }
      & Pick<Types.SensorGroup, 'id'>
    )> }
  )> }
);

export type DeleteSensorGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteSensorGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteASensorGroup?: Types.Maybe<(
    { __typename?: 'deleteASensorGroupPayload' }
    & { sensorGroup?: Types.Maybe<(
      { __typename?: 'SensorGroup' }
      & Pick<Types.SensorGroup, 'id'>
    )> }
  )> }
);


export const CreateSensorGroupDocument = gql`
    mutation CreateSensorGroup($name: String!, $parent: String) {
  createSensorGroup(input: {name: $name, parent: $parent}) {
    sensorGroup {
      id
      name
      parent {
        id
      }
    }
  }
}
    `;
export type CreateSensorGroupMutationFn = Apollo.MutationFunction<CreateSensorGroupMutation, CreateSensorGroupMutationVariables>;

/**
 * __useCreateSensorGroupMutation__
 *
 * To run a mutation, you first call `useCreateSensorGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorGroupMutation, { data, loading, error }] = useCreateSensorGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useCreateSensorGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateSensorGroupMutation, CreateSensorGroupMutationVariables>) {
        return Apollo.useMutation<CreateSensorGroupMutation, CreateSensorGroupMutationVariables>(CreateSensorGroupDocument, baseOptions);
      }
export type CreateSensorGroupMutationHookResult = ReturnType<typeof useCreateSensorGroupMutation>;
export type CreateSensorGroupMutationResult = Apollo.MutationResult<CreateSensorGroupMutation>;
export type CreateSensorGroupMutationOptions = Apollo.BaseMutationOptions<CreateSensorGroupMutation, CreateSensorGroupMutationVariables>;
export const UpdateSensorGroupNameDocument = gql`
    mutation UpdateSensorGroupName($id: ID!, $name: String!) {
  updateASensorGroup(input: {id: $id, name: $name}) {
    sensorGroup {
      id
    }
  }
}
    `;
export type UpdateSensorGroupNameMutationFn = Apollo.MutationFunction<UpdateSensorGroupNameMutation, UpdateSensorGroupNameMutationVariables>;

/**
 * __useUpdateSensorGroupNameMutation__
 *
 * To run a mutation, you first call `useUpdateSensorGroupNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorGroupNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorGroupNameMutation, { data, loading, error }] = useUpdateSensorGroupNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSensorGroupNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensorGroupNameMutation, UpdateSensorGroupNameMutationVariables>) {
        return Apollo.useMutation<UpdateSensorGroupNameMutation, UpdateSensorGroupNameMutationVariables>(UpdateSensorGroupNameDocument, baseOptions);
      }
export type UpdateSensorGroupNameMutationHookResult = ReturnType<typeof useUpdateSensorGroupNameMutation>;
export type UpdateSensorGroupNameMutationResult = Apollo.MutationResult<UpdateSensorGroupNameMutation>;
export type UpdateSensorGroupNameMutationOptions = Apollo.BaseMutationOptions<UpdateSensorGroupNameMutation, UpdateSensorGroupNameMutationVariables>;
export const DeleteSensorGroupDocument = gql`
    mutation DeleteSensorGroup($id: ID!) {
  deleteASensorGroup(input: {id: $id}) {
    sensorGroup {
      id
    }
  }
}
    `;
export type DeleteSensorGroupMutationFn = Apollo.MutationFunction<DeleteSensorGroupMutation, DeleteSensorGroupMutationVariables>;

/**
 * __useDeleteSensorGroupMutation__
 *
 * To run a mutation, you first call `useDeleteSensorGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorGroupMutation, { data, loading, error }] = useDeleteSensorGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSensorGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSensorGroupMutation, DeleteSensorGroupMutationVariables>) {
        return Apollo.useMutation<DeleteSensorGroupMutation, DeleteSensorGroupMutationVariables>(DeleteSensorGroupDocument, baseOptions);
      }
export type DeleteSensorGroupMutationHookResult = ReturnType<typeof useDeleteSensorGroupMutation>;
export type DeleteSensorGroupMutationResult = Apollo.MutationResult<DeleteSensorGroupMutation>;
export type DeleteSensorGroupMutationOptions = Apollo.BaseMutationOptions<DeleteSensorGroupMutation, DeleteSensorGroupMutationVariables>;