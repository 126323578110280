import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddSensorCommentMutationVariables = Types.Exact<{
  sensorId?: Types.Maybe<Types.Scalars['String']>;
  userId?: Types.Maybe<Types.Scalars['String']>;
  text: Types.Scalars['String'];
}>;


export type AddSensorCommentMutation = (
  { __typename?: 'Mutation' }
  & { createSensorComment?: Types.Maybe<(
    { __typename?: 'createSensorCommentPayload' }
    & { sensorComment?: Types.Maybe<(
      { __typename?: 'SensorComment' }
      & Pick<Types.SensorComment, 'id'>
    )> }
  )> }
);


export const AddSensorCommentDocument = gql`
    mutation addSensorComment($sensorId: String, $userId: String, $text: String!) {
  createSensorComment(input: {sensor: $sensorId, user: $userId, text: $text}) {
    sensorComment {
      id
    }
  }
}
    `;
export type AddSensorCommentMutationFn = Apollo.MutationFunction<AddSensorCommentMutation, AddSensorCommentMutationVariables>;

/**
 * __useAddSensorCommentMutation__
 *
 * To run a mutation, you first call `useAddSensorCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSensorCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSensorCommentMutation, { data, loading, error }] = useAddSensorCommentMutation({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      userId: // value for 'userId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAddSensorCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddSensorCommentMutation, AddSensorCommentMutationVariables>) {
        return Apollo.useMutation<AddSensorCommentMutation, AddSensorCommentMutationVariables>(AddSensorCommentDocument, baseOptions);
      }
export type AddSensorCommentMutationHookResult = ReturnType<typeof useAddSensorCommentMutation>;
export type AddSensorCommentMutationResult = Apollo.MutationResult<AddSensorCommentMutation>;
export type AddSensorCommentMutationOptions = Apollo.BaseMutationOptions<AddSensorCommentMutation, AddSensorCommentMutationVariables>;