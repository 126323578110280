import React from "react";
import { Switch, Route } from "react-router";
import { UserListScene } from "./UserListScene";
import { UserDetailsScene } from "./UserDetailsScene";
import { UserAddScene } from "./UserAddScene";

interface Props {}

export const UserManagementScene: React.FunctionComponent<Props> = (
  props: Props
) => {
  return (
    <Switch>
      <Route path="/users/add">
        <UserAddScene />
      </Route>
      <Route path="/users/:userId">
        <UserDetailsScene />
      </Route>
      <Route path="/users">
        <UserListScene />
      </Route>
    </Switch>
  );
};
