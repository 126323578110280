import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
export type BackupApplicationSettingsFragment = (
  { __typename?: 'ApplicationSettings' }
  & Pick<Types.ApplicationSettings, 'backupIntervalLength' | 'backupRetentionCount' | 'backupIntervalUnit'>
);

export const BackupApplicationSettingsFragmentDoc = gql`
    fragment BackupApplicationSettings on ApplicationSettings {
  backupIntervalLength
  backupRetentionCount
  backupIntervalUnit
}
    `;