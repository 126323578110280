import { gql } from "@apollo/client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { useToggle } from "../hook/useToggle";
import { AppVersion } from "./AppVersion";
import {
  useCurrentUserQuery,
  useUnapprovedSensorsCountQuery,
} from "./AuthenticatedNavigation.generated";
import { Navigation } from "./Navigation";
import { MobileNavLink } from "./Navigation/MobileNavLink";

interface Props {
  onLogout: () => void;
}

export const currentUserQuery = gql`
  query CurrentUser {
    currentUser {
      ...UserData
    }
  }
`;

export const UNAPPROVED_SENSORS_COUNT = gql`
  query UnapprovedSensorsCount {
    sensors(approved: false) {
      totalCount
    }
    getApplicationInformation {
      automaticSensorApprovalEnabled
    }
  }
`;

export const AuthenticatedNavigation: React.FunctionComponent<Props> = ({
  onLogout,
}) => {
  const [popoverOpen, setPopOverOpen] = useState(false);
  const [menuOpen, toggleMenuOpen] = useToggle();
  const { data } = useCurrentUserQuery();
  const { data: unapprovedSensorsData } = useUnapprovedSensorsCountQuery({
    pollInterval: 15000,
  });

  const hasUnapprovedSensors =
    (unapprovedSensorsData?.sensors?.totalCount || 0) > 0;

  if (!data) {
    return null;
  }

  const menuItems: Parameters<typeof Navigation>[0]["menuItems"] = [
    { title: "Sensors", path: "/sensor" },
    {
      title: "Approval",
      path: "/sensorApproval",
      highlight: hasUnapprovedSensors,
      hidden:
        unapprovedSensorsData?.getApplicationInformation
          ?.automaticSensorApprovalEnabled && !hasUnapprovedSensors,
    },
    { title: "Firmware", path: "/firmware" },
    {
      title: "Users",
      path: "/users",
      hidden: !data.currentUser?.roles.includes("ROLE_ADMIN"),
    },
    {
      title: "Admin",
      path: "/admin",
      hidden: !data.currentUser?.roles.includes("ROLE_ADMIN"),
    },
  ];

  return (
    <Navigation menuItems={menuItems}>
      {/* Mobile menu button */}
      <div className="block lg:hidden relative">
        {menuItems.reduce(
          (highlight, menuItem) => highlight || !!menuItem.highlight,
          false
        ) && (
          <span
            style={{ top: "-5px", left: "-5px" }}
            className="absolute block h-3 w-3 rounded-full text-white shadow-solid bg-red-500"
          ></span>
        )}
        <button
          className="flex items-center px-3 py-2 border rounded text-primary-200 border-primary-400 hover:text-white hover:border-white"
          onClick={toggleMenuOpen}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      {/* Mobile menu content */}
      <div className={`${menuOpen ? "block" : "hidden"} lg:hidden min-w-full`}>
        {/* Mobile navigation */}
        <div className="px-2 pt-2 pb-3">
          {menuItems.map((menuItem) => (
            <MobileNavLink
              key={menuItem.title}
              to={menuItem.path}
              onClick={toggleMenuOpen}
              highlight={menuItem.highlight}
            >
              {menuItem.title}
            </MobileNavLink>
          ))}
        </div>
        {/* Mobile menu items */}
        <div className="pt-4 pb-3 border-t border-gray-700">
          <div className="flex items-center px-2">
            <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
              <svg
                className="h-full w-full text-primary-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
            <span className="ml-2 text-primary-200">
              {data?.currentUser?.username}
            </span>
          </div>
          <div className="mt-3 px-2">
            <Link to="/profile" onClick={(event) => setPopOverOpen(false)}>
              <div className="block mt-4 lg:inline-block lg:mt-0 text-primary-200 hover:text-white mr-4 cursor-pointer">
                Your Profile
              </div>
            </Link>
            <div
              className="block mt-4 lg:inline-block lg:mt-0 text-primary-200 hover:text-white mr-4 cursor-pointer"
              onClick={(event) => {
                event.preventDefault();
                onLogout();
              }}
            >
              Sign out
            </div>
            <div className="block py-4 text-xs text-gray-500">
              <AppVersion></AppVersion>
            </div>
          </div>
        </div>
      </div>

      {/* Larger screen menu */}
      <div className="hidden lg:block">
        <div className="ml-4 flex items-center md:ml-6">
          <div className="ml-3 relative">
            {/* Larger screen menu button*/}
            {/* Larger screen menu popup */}
            <Popover
              isOpen={popoverOpen}
              onClickOutside={() => setPopOverOpen(false)}
              positions={["bottom"]}
              align="end"
              padding={0}
              content={
                <div className="mt-2 w-48 rounded-md shadow-lg">
                  <div className="py-1 rounded-md bg-white shadow-xs">
                    <Link
                      to="/profile"
                      onClick={(event) => setPopOverOpen(false)}
                    >
                      <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                        Your Profile
                      </div>
                    </Link>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={(event) => {
                        event.preventDefault();
                        onLogout();
                      }}
                    >
                      Sign out
                    </div>
                    <div className="block px-4 py-2 text-xs text-gray-500 text-center">
                      <AppVersion></AppVersion>
                    </div>
                  </div>
                </div>
              }
            >
              <div>
                <button
                  className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setPopOverOpen(!popoverOpen);
                  }}
                >
                  <span className="mr-2">{data?.currentUser?.username}</span>
                  <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                    <svg
                      className="h-full w-full text-primary-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </button>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </Navigation>
  );
};
