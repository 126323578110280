import React, { useEffect, useRef } from "react";
import Transition from "../util/Transition";

interface Props {
  show: boolean;
  onCancel: () => void;
}

export const ModalDialog: React.FunctionComponent<Props> = ({
  show,
  onCancel,
  children,
}) => {
  const modalBackdropRef = useRef<HTMLDivElement>(null);
  const modalWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalWindow = modalWindowRef.current;

    show && modalWindow && modalWindow.focus();
  }, [show]);

  return (
    <div className="z-30 fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center invisible">
      <Transition
        show={show}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        nodeRef={modalBackdropRef}
      >
        <div
          className="fixed inset-0 transition-opacity visible"
          ref={modalBackdropRef}
          onClick={() => onCancel()}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </Transition>
      <Transition
        show={show}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        nodeRef={modalWindowRef}
      >
        <div
          className="z-40 bg-white rounded-lg p-1 shadow-xl transform transition-all sm:max-w-lg sm:w-full visible outline-none"
          role="dialog"
          ref={modalWindowRef}
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabIndex={-1}
          onKeyDown={(event) => event.key === "Escape" && onCancel()}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
};
