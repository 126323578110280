import React from "react";
import { SensorDetailInformation } from "../component/SensorDetailInformation";
import { useSensorDetailQuery } from "../../../graphql/Sensor.generated";
import { useRouteMatch } from "react-router";
import { SensorConnectionLogContainer } from "./SensorConnectionLogContainer";
import { Panel } from "../../../component/Panel";
import { SensorCommentsContainer } from "./SensorCommentsContainer";
import { useCurrentUserQuery } from "../../../component/AuthenticatedNavigation.generated";
import { SensorOperations } from "./SensorOperations";

interface Props {}

export const SensorDetailScene: React.FunctionComponent<Props> = () => {
  const match = useRouteMatch<{ sensorId: string }>("/sensor/:sensorId");
  const sensorId = decodeURIComponent(match?.params.sensorId ?? "");
  const { data, error, loading } = useSensorDetailQuery({
    variables: { id: sensorId },
  });
  const { data: userData, loading: userLoading } = useCurrentUserQuery();

  if (error) {
    return <span>{error.message}</span>;
  }
  if (loading || userLoading || !userData?.currentUser || !data?.sensor) {
    return <span>Loading…</span>;
  }

  return (
    <React.Fragment>
      <div className="flex flex-wrap flex-grow sm:flex-col lg:flex-row mb-4">
        <div className="lg:flex-grow lg:flex-1">
          <SensorDetailInformation sensor={data.sensor} />
          <Panel>
            <SensorConnectionLogContainer deviceName={data.sensor.deviceName} />
          </Panel>
        </div>
        <div className="flex flex-col flex-grow lg:flex-1">
          <Panel>
            {!data.sensor.vpnStatus && (
              <div className="absolute w-full h-full flex bg-white bg-opacity-75 backdrop-blur-2px">
                <div className="m-auto">
                  You need to connect this sensor to the VPN to use the
                  maintenance operations.
                </div>
              </div>
            )}
            <SensorOperations sensor={data.sensor} />
          </Panel>
          <Panel>
            <SensorCommentsContainer
              user={userData.currentUser}
              sensor={data.sensor}
            />
          </Panel>
        </div>
      </div>
    </React.Fragment>
  );
};
