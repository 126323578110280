import * as Types from '../../../generated/graphql';

import { SensorGroupNodeFragment } from '../../../graphql/SensorGroup.generated';
import { gql } from '@apollo/client';
import { SensorGroupNodeFragmentDoc } from '../../../graphql/SensorGroup.generated';
import * as Apollo from '@apollo/client';
export type SensorGroupsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
}>;


export type SensorGroupsQuery = (
  { __typename?: 'Query' }
  & { sensorGroups?: Types.Maybe<(
    { __typename?: 'SensorGroupConnection' }
    & { edges?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'SensorGroupEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'SensorGroup' }
        & SensorGroupNodeFragment
      )> }
    )>>> }
  )> }
);


export const SensorGroupsDocument = gql`
    query SensorGroups($limit: Int!) {
  sensorGroups(first: $limit) {
    edges {
      node {
        ...SensorGroupNode
      }
    }
  }
}
    ${SensorGroupNodeFragmentDoc}`;

/**
 * __useSensorGroupsQuery__
 *
 * To run a query within a React component, call `useSensorGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorGroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSensorGroupsQuery(baseOptions: Apollo.QueryHookOptions<SensorGroupsQuery, SensorGroupsQueryVariables>) {
        return Apollo.useQuery<SensorGroupsQuery, SensorGroupsQueryVariables>(SensorGroupsDocument, baseOptions);
      }
export function useSensorGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorGroupsQuery, SensorGroupsQueryVariables>) {
          return Apollo.useLazyQuery<SensorGroupsQuery, SensorGroupsQueryVariables>(SensorGroupsDocument, baseOptions);
        }
export type SensorGroupsQueryHookResult = ReturnType<typeof useSensorGroupsQuery>;
export type SensorGroupsLazyQueryHookResult = ReturnType<typeof useSensorGroupsLazyQuery>;
export type SensorGroupsQueryResult = Apollo.QueryResult<SensorGroupsQuery, SensorGroupsQueryVariables>;