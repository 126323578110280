import React from "react";
import {
  SensorDetailFragment,
  useFavoriteSensorMutation,
} from "../../../graphql/Sensor.generated";
import { SensorDetailInformationRow } from "./SensorDetailInformationRow";
import { SensorStatus } from "./SensorStatus";
import { Panel } from "../../../component/Panel";
import { FileDownloader } from "../../../util/FileDownloader";
import { useEnvironment } from "../../../core/environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import { SensorGroupAssignment } from "./SensorGroupAssignment";

interface Props {
  sensor: SensorDetailFragment;
}

export const SensorDetailInformation: React.FunctionComponent<Props> = ({
  sensor,
}) => {
  const { environment } = useEnvironment();
  const [favoriteSensor] = useFavoriteSensorMutation();

  const downloadVpnProfile = async () => {
    const url = `${environment.baseUrl}/api/${sensor.deviceName}/GetDeviceConfig`;
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    const fileDownloader = new FileDownloader();

    await fileDownloader.downloadFile(
      `${sensor.deviceName}.ovpn`,
      url,
      "POST",
      headers
    );
  };

  const toggleSensorFavorite = () => {
    favoriteSensor({
      variables: { id: sensor.id, favorite: !sensor.favorite },
    });
  };

  return (
    <Panel>
      <div className="mx-4 py-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Sensor <span className="select-all"> {sensor.deviceName}</span>
          <FontAwesomeIcon
            className="float-right text-sunglow-500 hover:text-sunglow-600 cursor-pointer"
            onClick={() => {
              toggleSensorFavorite();
            }}
            icon={sensor.favorite ? faStar : faStarRegular}
          />
          <span className="float-right">
            <SensorStatus sensor={sensor} />
          </span>
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {sensor.type === "control"
            ? "Control Client"
            : "Advanced People Sensor"}{" "}
          created at {new Date(sensor.createdAt).toLocaleString()}
        </p>
      </div>
      <div>
        <dl>
          <SensorDetailInformationRow title="Assigned to group">
            <SensorGroupAssignment sensor={sensor} />
          </SensorDetailInformationRow>

          <SensorDetailInformationRow title="Certificate valid until">
            {new Date(sensor.certificateValidUntil).toLocaleString()}
          </SensorDetailInformationRow>

          {sensor.vpnStatus && (
            <>
              <SensorDetailInformationRow title="Connected at">
                {new Date(sensor.vpnStatus.connectedSince).toLocaleString()}
              </SensorDetailInformationRow>
              <SensorDetailInformationRow title="Address in VPN">
                {sensor.vpnStatus.vpnAddress}
              </SensorDetailInformationRow>
              <SensorDetailInformationRow title="Public Address used for VPN connection">
                {sensor.vpnStatus.publicAddress}
              </SensorDetailInformationRow>
            </>
          )}

          {sensor.status?.macAddress && (
            <SensorDetailInformationRow title="MAC address">
              <span className="uppercase">{sensor.status.macAddress}</span>
            </SensorDetailInformationRow>
          )}

          {sensor.status?.firmwareVersion && (
            <SensorDetailInformationRow title="Firmware version">
              {sensor.status.firmwareVersion}
            </SensorDetailInformationRow>
          )}

          {sensor.status?.modelType && (
            <SensorDetailInformationRow title="Product name">
              {sensor.status.modelType}
            </SensorDetailInformationRow>
          )}

          {sensor.status?.hardwareType && (
            <SensorDetailInformationRow title="Hardware type">
              {sensor.status.hardwareType}
            </SensorDetailInformationRow>
          )}

          {sensor.status?.timezone && (
            <SensorDetailInformationRow title="Timezone">
              {sensor.status.timezone.name} (UTC Offset{" "}
              {sensor.status.timezone.utcOffset})
            </SensorDetailInformationRow>
          )}

          {sensor.status?.licenses && (
            <SensorDetailInformationRow title="Licenses">
              {sensor.status.licenses.join(", ")}
            </SensorDetailInformationRow>
          )}

          {sensor.status?.hmiConnection && (
            <SensorDetailInformationRow title="HMI Connection">
              {sensor.status.hmiConnection.protocol} on port{" "}
              {sensor.status.hmiConnection.port}
            </SensorDetailInformationRow>
          )}

          {sensor.shouldConnectChanged && (
            <SensorDetailInformationRow title="Connection requested at">
              {new Date(sensor.shouldConnectChanged).toLocaleString()}
            </SensorDetailInformationRow>
          )}

          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm leading-5 font-medium text-gray-500">
              VPN Configuration
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                  <div className="w-0 flex-1 flex items-center">
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-2 flex-1 w-0 truncate">
                      {sensor.deviceName}.ovpn
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        downloadVpnProfile();
                      }}
                      className="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out focus:outline-none"
                    >
                      Download
                    </button>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </Panel>
  );
};
