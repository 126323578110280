import * as Types from '../../../generated/graphql';

import { SensorRowFragment } from '../../../graphql/Sensor.generated';
import { gql } from '@apollo/client';
import { SensorRowFragmentDoc } from '../../../graphql/Sensor.generated';
import * as Apollo from '@apollo/client';
export type SensorsQueryVariables = Types.Exact<{
  order?: Types.Maybe<Types.SensorFilter_Order>;
  search?: Types.Maybe<Types.Scalars['String']>;
  favorite?: Types.Maybe<Types.Scalars['Boolean']>;
  pageSize: Types.Scalars['Int'];
  after?: Types.Maybe<Types.Scalars['String']>;
  before?: Types.Maybe<Types.Scalars['String']>;
  sensorGroups?: Types.Maybe<Types.Scalars['String']>;
  approved?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type SensorsQuery = (
  { __typename?: 'Query' }
  & { sensors?: Types.Maybe<(
    { __typename?: 'SensorConnection' }
    & Pick<Types.SensorConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'SensorPageInfo' }
      & Pick<Types.SensorPageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'SensorEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'Sensor' }
        & SensorRowFragment
      )> }
    )>>> }
  )> }
);


export const SensorsDocument = gql`
    query Sensors($order: SensorFilter_order, $search: String, $favorite: Boolean, $pageSize: Int!, $after: String, $before: String, $sensorGroups: String, $approved: Boolean) {
  sensors(
    sensorGroups: $sensorGroups
    search: $search
    order: $order
    favorite: $favorite
    first: $pageSize
    after: $after
    before: $before
    approved: $approved
  ) @connection(key: "sensors", filters: ["search", "pageSize"]) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...SensorRow
      }
    }
  }
}
    ${SensorRowFragmentDoc}`;

/**
 * __useSensorsQuery__
 *
 * To run a query within a React component, call `useSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      search: // value for 'search'
 *      favorite: // value for 'favorite'
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sensorGroups: // value for 'sensorGroups'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useSensorsQuery(baseOptions: Apollo.QueryHookOptions<SensorsQuery, SensorsQueryVariables>) {
        return Apollo.useQuery<SensorsQuery, SensorsQueryVariables>(SensorsDocument, baseOptions);
      }
export function useSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorsQuery, SensorsQueryVariables>) {
          return Apollo.useLazyQuery<SensorsQuery, SensorsQueryVariables>(SensorsDocument, baseOptions);
        }
export type SensorsQueryHookResult = ReturnType<typeof useSensorsQuery>;
export type SensorsLazyQueryHookResult = ReturnType<typeof useSensorsLazyQuery>;
export type SensorsQueryResult = Apollo.QueryResult<SensorsQuery, SensorsQueryVariables>;