import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { TreeNode } from "./TreeNode";
import { useConfirmationDialog } from "../../../../../hook/useConfirmationDialog";
import { useDeleteSensorGroupMutation } from "../../../component/SensorGroups.generated";

interface Props {
  node: TreeNode<unknown>;
}

export const DeleteSensorGroupButton: React.FunctionComponent<Props> = ({
  node,
}) => {
  const [deleteSensorGroup] = useDeleteSensorGroupMutation({
    variables: { id: node.id },
    refetchQueries: ["SensorGroups"],
  });
  const { showConfirmationDialog } = useConfirmationDialog(
    {
      title: `Delete node`,
      confirmButtonText: "Delete",
      message: `Do you really want to delete the group "${node.name}"?`,
      onConfirm: () => {
        deleteSensorGroup().then();
      },
    },
    [node]
  );

  return (
    <button
      className="block px-4 py-2 text-sm leading-5 text-red-500 w-full text-left hover:bg-red-100 focus:outline-none"
      role="menuitem"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        showConfirmationDialog();
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
      <span className="ml-2">Delete Group</span>
    </button>
  );
};
