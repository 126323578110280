import React, { useState } from "react";
import { useEnvironment } from "../../core/environment";
import { LoginScreen } from "./component/LoginScreen";
import { TwoFactorLoginScreen } from "./component/TwoFactorLoginScreen";
import { Route, Switch, useHistory } from "react-router";
import {
  Credentials,
  SetupTwoFactorAuthScene,
} from "./SetupTwoFactorAuthScene";

interface Props {
  onSuccessfulLogin: () => void;
}

// From https://tailwindcomponents.com/component/sb-admin-2-login-page
export const LoginScene: React.FunctionComponent<Props> = ({
  onSuccessfulLogin,
}) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { environment } = useEnvironment();
  const [credentials, setCredentials] = useState<Credentials | null>(null);
  const [trustDevice, setTrustDevice] = useState(false);

  const verifyLogin = async (username: string, password: string) => {
    setErrorMessage(null);
    if (username.trim().length === 0) {
      setErrorMessage("Empty username.");
      return;
    }
    if (password.trim().length === 0) {
      setErrorMessage("Empty password.");
      return;
    }

    const csrfResponse = await fetch(
      environment.baseUrl + "/login_csrf_token",
      {
        credentials: "include",
      }
    );
    const csrfData = await csrfResponse.json();

    const response = await fetch(environment.baseUrl + "/login", {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        _csrf_token: csrfData.token,
      }),
    });
    if (response.status === 200) {
      onSuccessfulLogin();
      return;
    }
    if (response.status === 401) {
      const responseData = await response.json();
      if (responseData.reason === "second_factor_required") {
        history.push("/login/2fa");
        setErrorMessage("");
      } else if (responseData.reason === "two_factor_auth_setup_required") {
        setCredentials({
          username,
          password,
        });
        history.push("/login/setup_2fa");
        setErrorMessage("");
      } else {
        setErrorMessage("Invalid credential");
      }
      return;
    }

    setErrorMessage("Unexpected login error");
  };
  const verifyToken = async (token: number) => {
    const formData = new FormData();
    formData.append("_auth_code", String(token));
    const response = await fetch(
      `${environment.baseUrl}/2fa_check?${trustDevice ? "_trusted=true" : ""}`,
      {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      }
    );

    if (response.status === 200) {
      history.push("/");
      onSuccessfulLogin();
      return;
    }
    if (response.status !== 401) {
      setErrorMessage("Unexpected login error");
      return;
    }
    setErrorMessage("Token invalid");
  };

  return (
    <Switch>
      <Route path="/login/setup_2fa">
        <SetupTwoFactorAuthScene credentials={credentials} />
      </Route>
      <Route path="/login/2fa">
        <TwoFactorLoginScreen
          errorMessage={errorMessage}
          verifyToken={verifyToken}
          trustDevice={trustDevice}
          setTrustDevice={setTrustDevice}
        />
      </Route>
      <Route>
        <LoginScreen errorMessage={errorMessage} verifyLogin={verifyLogin} />
      </Route>
    </Switch>
  );
};
