import React from "react";
import { Button } from "../../../../../component/Button";
import { useToggle } from "../../../../../hook/useToggle";
import { NewSensorGroupInput } from "./NewGroupInput";

interface Props {}

export const AddSensorGroupButton: React.FunctionComponent<Props> = () => {
  const [active, toggleActive] = useToggle();
  if (active) {
    return (
      <NewSensorGroupInput onSuccess={toggleActive} onCancel={toggleActive} />
    );
  }

  return (
    <Button wide={true} onClick={toggleActive}>
      Add new group
    </Button>
  );
};
