import React from "react";
import { SensorGroupNodeFragment } from "../../../../graphql/SensorGroup.generated";
import { Item } from "../../../../hook/useItemSelection";
import { SensorGroupTreeNode } from "./SensorGroupTree/SensorGroupTreeNode";
import { TreeNode } from "./SensorGroupTree/TreeNode";
import { SensorGroupDragLayer } from "./SensorGroupTree/SensorGroupDragLayer";
import { IncomingGroupTreeNode } from "./SensorGroupTree/IncomingGroupTreeNode";
import { nodeListToTree } from "./SensorGroupTree/nodeListToTree";

interface Props {
  nodes: Array<Item<SensorGroupNodeFragment>>;
  maxDepth?: number;
}

export const SensorGroupTree: React.FunctionComponent<Props> = ({
  nodes,
  maxDepth,
}) => {
  const [incomingGroup] = nodes.filter((node) => node.item.id === "");
  const sensorGroups = nodes.filter((node) => node.item.id !== "");

  const treeData = nodeListToTree(sensorGroups);

  return (
    <div className={"flex flex-grow flex-col"}>
      <SensorGroupDragLayer />
      {incomingGroup && (
        <IncomingGroupTreeNode group={incomingGroup}></IncomingGroupTreeNode>
      )}
      {treeData.root.children.map(
        (topLevelNode: TreeNode<Item<SensorGroupNodeFragment>>) => (
          <SensorGroupTreeNode
            path={[topLevelNode.id]}
            key={topLevelNode.id}
            node={topLevelNode}
            depth={0}
            maxDepth={maxDepth ?? 4}
          />
        )
      )}
    </div>
  );
};
