import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";

interface Props {
  to: string;
  highlight?: boolean;
}

export const NavLink: React.FunctionComponent<Props> = ({
  to,
  highlight,
  children,
}) => {
  let match = useRouteMatch({
    path: to,
  });

  return (
    <div className="inline-block relative">
      {highlight && (
        <span className="absolute top-0 left-0 block h-3 w-3 rounded-full text-white shadow-solid bg-red-500"></span>
      )}
      <Link
        to={to}
        className={classNames({
          "text-white bg-primary-600": match,
          "text-primary-200 text:underlined": !match,
          "block px-3 py-2 hover:bg-primary-700 mt-4 lg:inline-block lg:mt-0 hover:text-white mr-4 rounded-md": true,
        })}
      >
        {children}
      </Link>
    </div>
  );
};
