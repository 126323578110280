import React, { useContext, useState } from "react";

type Environment = {
  baseUrl: string;
};

type EnvironmentContextShape = {
  environment: Environment;
  setEnvironment: (environment: Environment) => void;
};

const EnvironmentContext: React.Context<
  EnvironmentContextShape | undefined
> = React.createContext<undefined | EnvironmentContextShape>(undefined);

export const EnvironmentProvider: React.FunctionComponent = ({ children }) => {
  const [environment, setEnvironment] = useState<Environment>({
    baseUrl: "/control",
  });

  return (
    <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export function useEnvironment(): EnvironmentContextShape {
  const environment: EnvironmentContextShape | undefined = useContext(
    EnvironmentContext
  );

  if (!environment) {
    throw new Error("Internal Error - no Environment in Context");
  }

  return environment;
}
