import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SensorFirmwareNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SensorFirmwareNamesQuery = (
  { __typename?: 'Query' }
  & { sensorFirmwares?: Types.Maybe<(
    { __typename?: 'SensorFirmwareConnection' }
    & { edges?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'SensorFirmwareEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'SensorFirmware' }
        & Pick<Types.SensorFirmware, 'name'>
      )> }
    )>>> }
  )> }
);


export const SensorFirmwareNamesDocument = gql`
    query SensorFirmwareNames {
  sensorFirmwares {
    edges {
      node {
        name
      }
    }
  }
}
    `;

/**
 * __useSensorFirmwareNamesQuery__
 *
 * To run a query within a React component, call `useSensorFirmwareNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorFirmwareNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorFirmwareNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSensorFirmwareNamesQuery(baseOptions?: Apollo.QueryHookOptions<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>) {
        return Apollo.useQuery<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>(SensorFirmwareNamesDocument, baseOptions);
      }
export function useSensorFirmwareNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>) {
          return Apollo.useLazyQuery<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>(SensorFirmwareNamesDocument, baseOptions);
        }
export type SensorFirmwareNamesQueryHookResult = ReturnType<typeof useSensorFirmwareNamesQuery>;
export type SensorFirmwareNamesLazyQueryHookResult = ReturnType<typeof useSensorFirmwareNamesLazyQuery>;
export type SensorFirmwareNamesQueryResult = Apollo.QueryResult<SensorFirmwareNamesQuery, SensorFirmwareNamesQueryVariables>;