import * as Types from '../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UserRowFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'id' | 'username' | 'roles' | 'twoFactorAuthEnabled'>
);

export type UserDataFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'id' | 'username' | 'roles' | 'twoFactorAuthEnabled'>
  & { sensorCredentials?: Types.Maybe<(
    { __typename?: 'SensorCredentials' }
    & Pick<Types.SensorCredentials, 'id' | 'username' | 'password'>
  )> }
);

export type UserDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type UserDetailQuery = (
  { __typename?: 'Query' }
  & { user?: Types.Maybe<(
    { __typename?: 'User' }
    & UserRowFragment
  )> }
);

export const UserRowFragmentDoc = gql`
    fragment UserRow on User {
  id
  username
  roles
  twoFactorAuthEnabled
}
    `;
export const UserDataFragmentDoc = gql`
    fragment UserData on User {
  id
  username
  roles
  twoFactorAuthEnabled
  sensorCredentials {
    id
    username
    password
  }
}
    `;
export const UserDetailDocument = gql`
    query UserDetail($id: ID!) {
  user(id: $id) {
    ...UserRow
  }
}
    ${UserRowFragmentDoc}`;

/**
 * __useUserDetailQuery__
 *
 * To run a query within a React component, call `useUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDetailQuery(baseOptions: Apollo.QueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
        return Apollo.useQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, baseOptions);
      }
export function useUserDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
          return Apollo.useLazyQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, baseOptions);
        }
export type UserDetailQueryHookResult = ReturnType<typeof useUserDetailQuery>;
export type UserDetailLazyQueryHookResult = ReturnType<typeof useUserDetailLazyQuery>;
export type UserDetailQueryResult = Apollo.QueryResult<UserDetailQuery, UserDetailQueryVariables>;