import React, { useState } from "react";
import { Panel } from "../../../component/Panel";
import { Button } from "../../../component/Button";

interface Props {
  onCredentialsChanged: (username: string, password: string) => void;
  errorMessages: string[];
  disabled?: boolean;
  disabledReason?: string;
  username: string;
  password: string;
}

export const SensorCredentialsPanel: React.FunctionComponent<Props> = ({
  onCredentialsChanged,
  errorMessages,
  disabled,
  disabledReason,
  username: originalUsername,
  password: originalPassword,
}) => {
  const [username, setUsername] = useState(originalUsername);
  const [password, setPassword] = useState(originalPassword);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const verifyInput = () => {
    setErrorMessage(null);
    setPasswordError(false);
    setUsernameError(false);

    if (password.trim().length === 0) {
      setErrorMessage("Empty password.");
      setPasswordError(true);
      return false;
    }

    if (username.trim().length === 0) {
      setErrorMessage("Empty username.");
      setUsernameError(true);
      return false;
    }

    return true;
  };

  return (
    <Panel>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-8">
          Sensor Credentials
        </h3>
        <form>
          <p className="pb-5">
            These are the credentials to used when making maintenance operations
            on a Sensor. Please set them to match your assigned user account on
            the Sensor.
          </p>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Username
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="username"
                type="text"
                disabled={disabled}
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                required
                className={`appearance-none block w-full px-3 py-2 border ${
                  usernameError && "border-red-500"
                } rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          <div className="mt-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                disabled={disabled}
                id="token"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
                className={`appearance-none block w-full px-3 py-2 border ${
                  passwordError && "border-red-500"
                } rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              />
            </div>
          </div>

          {!!errorMessage && (
            <p className="text-xs italic text-red-500">{errorMessage}</p>
          )}

          {errorMessages.map((message, index) => (
            <p key={index} className="text-xs italic text-red-500">
              {message}
            </p>
          ))}

          {disabled && disabledReason && (
            <div className="flex justify-center items-center p-4">
              <p>{disabledReason}</p>
            </div>
          )}

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <Button
                wide={true}
                disabled={disabled}
                onClick={(event) => {
                  event.preventDefault();
                  if (verifyInput()) {
                    onCredentialsChanged(username, password);
                  }
                }}
              >
                Save
              </Button>
            </span>
          </div>
        </form>
      </div>
    </Panel>
  );
};
