import * as Types from '../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteRegularUser?: Types.Maybe<(
    { __typename?: 'deleteRegularUserPayload' }
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id'>
    )> }
  )> }
);

export type ChangePasswordForUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  password: Types.Scalars['String'];
}>;


export type ChangePasswordForUserMutation = (
  { __typename?: 'Mutation' }
  & { changePasswordForUser?: Types.Maybe<(
    { __typename?: 'changePasswordForUserPayload' }
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id'>
    )> }
  )> }
);

export type ChangeRolesForUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  roles: Types.Scalars['Iterable'];
}>;


export type ChangeRolesForUserMutation = (
  { __typename?: 'Mutation' }
  & { changeRolesForUser?: Types.Maybe<(
    { __typename?: 'changeRolesForUserPayload' }
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id' | 'roles'>
    )> }
  )> }
);

export type ResetTwoFactorAuthForUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ResetTwoFactorAuthForUserMutation = (
  { __typename?: 'Mutation' }
  & { resetTwoFactorAuthForUser?: Types.Maybe<(
    { __typename?: 'resetTwoFactorAuthForUserPayload' }
    & { user?: Types.Maybe<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id'>
    )> }
  )> }
);


export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteRegularUser(input: {id: $id}) {
    user {
      id
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ChangePasswordForUserDocument = gql`
    mutation changePasswordForUser($id: ID!, $password: String!) {
  changePasswordForUser(input: {id: $id, password: $password}) {
    user {
      id
    }
  }
}
    `;
export type ChangePasswordForUserMutationFn = Apollo.MutationFunction<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>;

/**
 * __useChangePasswordForUserMutation__
 *
 * To run a mutation, you first call `useChangePasswordForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordForUserMutation, { data, loading, error }] = useChangePasswordForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordForUserMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>) {
        return Apollo.useMutation<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>(ChangePasswordForUserDocument, baseOptions);
      }
export type ChangePasswordForUserMutationHookResult = ReturnType<typeof useChangePasswordForUserMutation>;
export type ChangePasswordForUserMutationResult = Apollo.MutationResult<ChangePasswordForUserMutation>;
export type ChangePasswordForUserMutationOptions = Apollo.BaseMutationOptions<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>;
export const ChangeRolesForUserDocument = gql`
    mutation changeRolesForUser($id: ID!, $roles: Iterable!) {
  changeRolesForUser(input: {id: $id, roles: $roles}) {
    user {
      id
      roles
    }
  }
}
    `;
export type ChangeRolesForUserMutationFn = Apollo.MutationFunction<ChangeRolesForUserMutation, ChangeRolesForUserMutationVariables>;

/**
 * __useChangeRolesForUserMutation__
 *
 * To run a mutation, you first call `useChangeRolesForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRolesForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRolesForUserMutation, { data, loading, error }] = useChangeRolesForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useChangeRolesForUserMutation(baseOptions?: Apollo.MutationHookOptions<ChangeRolesForUserMutation, ChangeRolesForUserMutationVariables>) {
        return Apollo.useMutation<ChangeRolesForUserMutation, ChangeRolesForUserMutationVariables>(ChangeRolesForUserDocument, baseOptions);
      }
export type ChangeRolesForUserMutationHookResult = ReturnType<typeof useChangeRolesForUserMutation>;
export type ChangeRolesForUserMutationResult = Apollo.MutationResult<ChangeRolesForUserMutation>;
export type ChangeRolesForUserMutationOptions = Apollo.BaseMutationOptions<ChangeRolesForUserMutation, ChangeRolesForUserMutationVariables>;
export const ResetTwoFactorAuthForUserDocument = gql`
    mutation resetTwoFactorAuthForUser($id: ID!) {
  resetTwoFactorAuthForUser(input: {id: $id}) {
    user {
      id
    }
  }
}
    `;
export type ResetTwoFactorAuthForUserMutationFn = Apollo.MutationFunction<ResetTwoFactorAuthForUserMutation, ResetTwoFactorAuthForUserMutationVariables>;

/**
 * __useResetTwoFactorAuthForUserMutation__
 *
 * To run a mutation, you first call `useResetTwoFactorAuthForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTwoFactorAuthForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTwoFactorAuthForUserMutation, { data, loading, error }] = useResetTwoFactorAuthForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetTwoFactorAuthForUserMutation(baseOptions?: Apollo.MutationHookOptions<ResetTwoFactorAuthForUserMutation, ResetTwoFactorAuthForUserMutationVariables>) {
        return Apollo.useMutation<ResetTwoFactorAuthForUserMutation, ResetTwoFactorAuthForUserMutationVariables>(ResetTwoFactorAuthForUserDocument, baseOptions);
      }
export type ResetTwoFactorAuthForUserMutationHookResult = ReturnType<typeof useResetTwoFactorAuthForUserMutation>;
export type ResetTwoFactorAuthForUserMutationResult = Apollo.MutationResult<ResetTwoFactorAuthForUserMutation>;
export type ResetTwoFactorAuthForUserMutationOptions = Apollo.BaseMutationOptions<ResetTwoFactorAuthForUserMutation, ResetTwoFactorAuthForUserMutationVariables>;