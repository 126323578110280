import React from "react";
import { Button } from "../../../../component/Button";
import { Link } from "react-router-dom";

interface Props {}

export const NoSensorsToApprovePlaceholder: React.FunctionComponent<Props> = () => {
  return (
    <div>
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8 h-full">
        <h3 className="text-2xl leading-9 text-gray-900 sm:text-2xl sm:leading-10">
          No sensors need approval right now.
        </h3>
        <div className="mt-2 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Button>
              <Link to={"/sensor"}>Go to sensors</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
