import React from "react";

interface Props {
  state: boolean;
  onChange: (state: boolean) => void;
  loading?: boolean;
}

export const Toggle: React.FunctionComponent<Props> = ({ state, onChange }) => {
  const offClasses =
    "absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-white";
  const onClasses =
    "absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-primary-500 transform translate-x-full";

  const classNames = state ? onClasses : offClasses;

  return (
    <label
      className="flex items-center cursor-pointer"
      onClick={(event) => {
        event.preventDefault();
        onChange(!state);
      }}
    >
      <span className="relative">
        <span className="block w-10 h-6 bg-gray-400 rounded-full shadow-inner"></span>
        <span className={classNames}>
          <input
            id="checked"
            type="checkbox"
            className="absolute opacity-0 w-0 h-0"
          />
        </span>
      </span>
    </label>
  );
};
