import React from "react";
import { LoginPanel } from "../LoginPanel";

interface Props {
  onNext: () => void;
}

export const Success: React.FunctionComponent<Props> = ({ onNext }) => {
  return (
    <LoginPanel>
      <h3 className="text-2xl text-center text-tradewind-500">Success!</h3>
      <div>You successfully configured Two Factor Authentication.</div>
      <div className="pt-2">Please proceed to the login now.</div>

      <div className="mt-12 text-center">
        <button
          className="w-full px-4 py-2 font-bold text-white bg-sunglow-500 rounded-full hover:bg-sunglow-600 focus:outline-none focus:shadow-outline"
          onClick={() => onNext()}
        >
          Go to Login
        </button>
      </div>
    </LoginPanel>
  );
};
