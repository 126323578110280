import React from "react";
import classNames from "classnames";
import { ConnectionLogEntryDataFragment } from "../../../graphql/ConnectionLogEntry.generated";

interface Props {
  connectionLogEntries: ConnectionLogEntryDataFragment[];
}

export const ConnectionLogTable: React.FunctionComponent<Props> = ({
  connectionLogEntries,
}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div>
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Statuscode
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Request
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Remote IP Address
                </th>
              </tr>
            </thead>
            <tbody>
              {connectionLogEntries.map((log, i) => (
                <tr
                  key={i}
                  className={classNames({ "bg-gray-100": i % 2 === 0 })}
                >
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <span
                      className={classNames(
                        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-green-800",
                        {
                          "bg-green-100": log.statusCode < 300,
                          "bg-yellow-100":
                            log.statusCode >= 300 && log.statusCode < 400,
                          "bg-red-100": log.statusCode >= 400,
                        }
                      )}
                    >
                      {log.statusCode}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div className="ml-4">
                      <div className="text-sm leading-5 font-medium text-gray-900">
                        {log.command}
                      </div>
                      <div className="text-sm leading-5 text-gray-500">
                        {log.method}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {new Date(log.timestamp).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                    {log.remoteAddress}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
