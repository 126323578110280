import React, { useState } from "react";
import { SensorGroupTreeScene } from "./SensorGroupTreeScene";
import { SensorsListScene } from "./SensorsListScene";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useToggle } from "../../../hook/useToggle";
import { AddSensorGroupButton } from "./SensorOverviewScene/SensorGroupTree/AddSensorGroupButton";
import { GroupFilterInput } from "./component/GroupFilterInput";
import { SensorGroupTreeHeader } from "./component/SensorGroupTreeHeader";
import { SensorGroupTreeDroppableContainer } from "./component/SensorGroupTreeDroppableContainer";

const SensorOverviewScene: React.FunctionComponent = () => {
  const [groupsExpanded, toggleGroupsExpanded] = useToggle(false);
  const [groupFilter, setGroupFilter] = useState("");

  return (
    <div className="md:flex-row flex-col flex md:h-full md:flex-shrink-0">
      {/* Sensor groups content */}
      <div className="flex flex-shrink-0 lg:w-1/5 xl:w-1/6 md:w-64 bg-gray-100">
        <div className="flex flex-auto flex-col overflow-x-auto">
          <div className="container mx-auto p-0 lg:p-4 flex flex-col md:h-0 flex-1 md:border-r md:border-gray-200 md:shadow">
            <div className={`flex flex-col md:hidden flex-shrink-0 px-8`}>
              <div className="flex items-center justify-between flex-shrink-0 py-4">
                <SensorGroupTreeHeader />
                <div className="block md:hidden">
                  <button
                    onClick={(event) => {
                      toggleGroupsExpanded();
                    }}
                    className="flex items-center px-3 py-2 border rounded border-gray-900 hover:text-gray-400 hover:border-gray-400 focus:outline-none"
                  >
                    <FontAwesomeIcon
                      icon={groupsExpanded ? faChevronDown : faChevronLeft}
                    ></FontAwesomeIcon>
                  </button>
                </div>
              </div>
              <div className={`${groupsExpanded ? "block" : "hidden"} my-2`}>
                <GroupFilterInput
                  value={groupFilter}
                  onChange={setGroupFilter}
                />
              </div>
            </div>
            <div className="hidden md:flex flex-row md:flex-col flex-shrink-0 px-4 pb-4 select-none">
              <SensorGroupTreeHeader />
              <div className="mt-2">
                <GroupFilterInput
                  value={groupFilter}
                  onChange={setGroupFilter}
                />
              </div>
            </div>
            <SensorGroupTreeDroppableContainer>
              <div className={`${groupsExpanded ? "flex" : "hidden"} md:flex`}>
                <SensorGroupTreeScene filter={groupFilter} />
              </div>
            </SensorGroupTreeDroppableContainer>
            <div
              className={`${
                groupsExpanded ? "flex" : "hidden"
              } md:flex flex-col flex-shrink-0 md:border-t border-gray-200 py-4 md:px-4 px-8`}
            >
              <AddSensorGroupButton />
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <div className="flex flex-col md:w-0 flex-1 overflow-hidden">
        <main
          className="flex-1 h-full relative z-0 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          <SensorsListScene />
        </main>
      </div>
    </div>
  );
};

export default SensorOverviewScene;
