import React, { useCallback, useEffect } from "react";
import { SensorGroupNodeFragment } from "../../../../../graphql/SensorGroup.generated";
import { Item } from "../../../../../hook/useItemSelection";
import { useToggle } from "../../../../../hook/useToggle";
import { EditableSensorGroupName } from "./EditableSensorGroupName";
import { NewSensorGroupInput } from "./NewGroupInput";
import { TreeNode } from "./TreeNode";
import { DeleteSensorGroupButton } from "./DeleteSensorGroupButton";
import { gql } from "@apollo/client";
import {
  useSetSensorGroupMutation,
  useUpdateSensorGroupParentMutation,
} from "./SensorGroupTreeNode.generated";
import { useDrop } from "../../../../../hook/dragAndDrop/useDrop";
import { useDrag } from "../../../../../hook/dragAndDrop/useDrag";
import { getEmptyImage } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { expandedStoreGroupNodes } from "../../../../../App";
import { ActionButton } from "../../../../../component/ActionButton";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";

interface Props {
  node: TreeNode<Item<SensorGroupNodeFragment>>;
  depth: number;
  maxDepth: number;
  path: string[];
}

interface DropCollectedProps {
  isOver: boolean;
  canDrop: boolean;
}

export const SET_SENSOR_GROUP = gql`
  mutation SetSensorGroup($id: ID!, $sensorGroup: String) {
    updateSensor(input: { id: $id, sensorGroup: $sensorGroup }) {
      sensor {
        id
        sensorGroup {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_SENSOR_GROUP_PARENT = gql`
  mutation UpdateSensorGroupParent($id: ID!, $parentId: String) {
    updateASensorGroup(input: { id: $id, parent: $parentId }) {
      sensorGroup {
        id
        name
        parent {
          id
        }
      }
    }
  }
`;

export const SensorGroupTreeNode: React.FunctionComponent<Props> = ({
  node,
  depth,
  maxDepth,
  path,
}) => {
  const { isExpanded } = node.item.item;
  const [setSensorGroup] = useSetSensorGroupMutation({
    refetchQueries: ["Sensors", "SensorGroups"],
  });

  const [updateSensorGroupParent] = useUpdateSensorGroupParentMutation({
    refetchQueries: ["Sensors"],
  });

  const [{ isOver, canDrop }, drop] = useDrop<DropCollectedProps>({
    accept: ["SENSORS", "SENSOR_GROUP"],
    drop: (item, monitor) => {
      if (item.type === "SENSORS") {
        item.draggedSensorIds.forEach((draggedSensorId) => {
          setSensorGroup({
            variables: { id: draggedSensorId, sensorGroup: node.item.item.id },
          });
        });
      }
      if (item.type === "SENSOR_GROUP") {
        updateSensorGroupParent({
          variables: {
            id: item.sensorGroupId,
            parentId: node.item.item.id,
          },
        });
      }
    },
    canDrop: (item) => {
      if (item.type === "SENSOR_GROUP") {
        if (depth >= maxDepth) {
          return false;
        }

        if (path.includes(item.sensorGroupId)) {
          return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [, drag, preview] = useDrag({
    item: {
      id: node.item.item.id,
      sensorGroupId: node.item.item.id,
      sensorGroupName: node.item.item.name,
      type: "SENSOR_GROUP",
    },
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const [isAddGroupActive, toggleAddGroupActive] = useToggle();
  const [isEditingName, toggleEditingName] = useToggle();

  const expandNode = useCallback(() => {
    expandedStoreGroupNodes([...expandedStoreGroupNodes(), node.id]);
  }, [node.id]);

  const collapseNode = () => {
    expandedStoreGroupNodes(
      expandedStoreGroupNodes().filter((id) => id !== node.id)
    );
  };

  const toggleNodeExpansion = () => {
    if (isExpanded) {
      collapseNode();
    } else {
      expandNode();
    }
  };

  const getItemIdsRecursively = (
    node: TreeNode<Item<SensorGroupNodeFragment>>
  ): string[] => {
    const childrenIds: string[] = node.children
      .map(getItemIdsRecursively)
      .flat();
    return [node.id, ...childrenIds];
  };

  const selectNodeAndChildrenRecursively = (
    node: TreeNode<Item<SensorGroupNodeFragment>>
  ) => {
    // @TODO remove the ts-expect-error, see https://suora.atlassian.net/browse/AGLAIAVPN-195
    // @ts-expect-error
    node.item.selectMultipleItems(getItemIdsRecursively(node));
  };

  const deselectNodeAndChildrenRecursively = (
    node: TreeNode<Item<SensorGroupNodeFragment>>
  ) => {
    // @TODO remove the ts-expect-error, see https://suora.atlassian.net/browse/AGLAIAVPN-195
    // @ts-expect-error
    node.item.deselectMultipleItems(getItemIdsRecursively(node));
  };

  const toggleNodeSelectionAndExpansion = () => {
    if (!node.item.isSelected) {
      // we want to select all children whenever an item becomes selected
      selectNodeAndChildrenRecursively(node);
      expandNode();
    } else {
      // respectively, deselect all children whenever an item becomes deselected
      deselectNodeAndChildrenRecursively(node);
      collapseNode();
    }
  };

  const getSensorCount = (
    node: TreeNode<Item<SensorGroupNodeFragment>>
  ): number => {
    return node.item.item.sensors?.totalCount || 0;
  };

  const getRecursiveSensorCount = (
    node: TreeNode<Item<SensorGroupNodeFragment>>
  ): number => {
    // This calculation should probably move to the generation of the tree nodes, eventually.
    return (
      getSensorCount(node) +
      node.children
        .map((child) => getRecursiveSensorCount(child))
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
    );
  };

  useEffect(() => {
    if (node.item.isSelected) {
      expandNode();
    }
  }, [expandNode, node.item.isSelected]);

  if (node.id.length === 0) {
    return <div>{node.name}</div>;
  }

  let hoverStyle = "cursor-pointer";

  if (isOver && canDrop) {
    hoverStyle = "bg-primary-400";
  }

  return (
    <div>
      <div ref={drag}>
        <div
          ref={drop}
          className={`flex py-2 hover:bg-gray-200 ${hoverStyle} items-center`}
          style={{
            paddingLeft: `calc(0.5rem + ${depth * 10}px)`,
          }}
          onClick={toggleNodeSelectionAndExpansion}
        >
          <button
            className="inline-block w-4 text-left hover:text-gray-400 hover:border-gray-400 focus:outline-none text-xs"
            onClick={(event) => {
              event.stopPropagation();
              toggleNodeExpansion();
            }}
          >
            {node.children.length > 0 && (
              <FontAwesomeIcon
                icon={isExpanded ? faChevronDown : faChevronRight}
              />
            )}
          </button>
          <input
            type="checkbox"
            className="cursor-pointer inline-block mr-2 mb-1"
            checked={node.item.isSelected}
            onClick={(event) => event.stopPropagation()}
            onChange={toggleNodeSelectionAndExpansion}
          />
          <EditableSensorGroupName
            node={node}
            editing={isEditingName}
            onEditComplete={toggleEditingName}
          />
          ({getRecursiveSensorCount(node)})
          <div className="inline-flex flex-grow" />
          <ActionButton>
            <div className="mt-2 w-56 rounded-md shadow-lg">
              <div
                className="rounded-md bg-white shadow-xs"
                role="menu"
                aria-orientation="vertical"
              >
                <DeleteSensorGroupButton node={node} />
                <button
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  onClick={() => {
                    expandNode();
                    toggleAddGroupActive();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="ml-2">Add Child Group</span>
                </button>
                <button
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  onClick={toggleEditingName}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <span className="ml-2">Edit Name</span>
                </button>
              </div>
            </div>
          </ActionButton>
        </div>
      </div>
      {isExpanded && depth < maxDepth && (
        <div>
          {node.children.map((childNode) => (
            <SensorGroupTreeNode
              key={childNode.id}
              node={childNode}
              depth={depth + 1}
              maxDepth={maxDepth}
              path={[...path, childNode.id]}
            />
          ))}
        </div>
      )}
      {isAddGroupActive && (
        <div style={{ paddingLeft: `calc(1.5rem + ${(depth + 1) * 10}px)` }}>
          <NewSensorGroupInput
            node={node}
            onSuccess={toggleAddGroupActive}
            onCancel={toggleAddGroupActive}
          />
        </div>
      )}
    </div>
  );
};
