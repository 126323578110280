import React from "react";
import { useSensorGroupsWithSelection } from "../../hook/useSensorGroupsWithSelection";

interface Props {}

export const ActiveSensorGroupFiltersList: React.FunctionComponent<Props> = () => {
  const {
    selection: { selectedItems },
  } = useSensorGroupsWithSelection();

  return (
    <div>
      {selectedItems.map((sensorGroupItem) => {
        return (
          <span
            key={sensorGroupItem.item.id}
            className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium leading-4 bg-primary-500 text-white mr-1"
          >
            {sensorGroupItem.item.name}
            <button
              type="button"
              className="flex-shrink-0 ml-1 inline-flex text-white focus:outline-none focus:text-gray-700"
              aria-label={`Remove ${sensorGroupItem.item.name} group filter`}
              onClick={sensorGroupItem.toggle}
            >
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        );
      })}
    </div>
  );
};
