import React, { ReactNode } from "react";
import { CellProps, Column, useTable } from "react-table";
import { SensorFirmwareTableRow } from "./SensorFirmwareTableRow";
import { SensorFirmware } from "../SensorFirmwareScene";
import { FileDownloader } from "../../../../util/FileDownloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { ActionButton } from "../../../../component/ActionButton";

interface Props {
  firmwares: SensorFirmware[];
  onDeleteFirmware: (firmware: SensorFirmware) => void;
  footer?: ReactNode;
}
const columns: Column<SensorFirmware>[] = [
  {
    Header: "Name",
    accessor: (row: SensorFirmware) => row.name,
  },
  {
    id: "Actions",
    Header: () => {
      return <div className="text-right" />;
    },
    Cell: (cellProps: CellProps<SensorFirmware>) => {
      if (!cellProps.row.original.contentUrl) {
        return null;
      }

      return (
        <div className="py-1 text-sm leading-5 text-right">
          <ActionButton>
            <div className="mt-2 w-56 rounded-md shadow-lg">
              <div
                className="rounded-md bg-white shadow-xs"
                role="menu"
                aria-orientation="vertical"
              >
                <button
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                  onClick={(event) => {
                    event.preventDefault();
                    // @ts-ignore
                    cellProps.downloadFirmware(cellProps.row.original);
                  }}
                >
                  <i className="icon-cloud_upload " />
                  <span className="ml-2">Download</span>
                </button>
                <button
                  className="block px-4 py-2 text-sm leading-5 text-red-500 w-full text-left hover:bg-red-100 focus:outline-none"
                  role="menuitem"
                  onClick={(event) => {
                    // @ts-ignore
                    cellProps.deleteFirmware(cellProps.row.original);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  <span className="ml-2">Delete</span>
                </button>
              </div>
            </div>
          </ActionButton>
        </div>
      );
    },
  },
];
export const SensorFirmwareTable: React.FunctionComponent<Props> = ({
  firmwares,
  onDeleteFirmware,
  footer,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: firmwares });

  const downloadFirmware = async (firmware: SensorFirmware) => {
    const url = firmware.contentUrl;
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const fileDownloader = new FileDownloader();

    await fileDownloader.downloadFile(
      `${firmware.name}.tar`,
      url!,
      "GET",
      headers
    );
  };

  return (
    <React.Fragment>
      <div className="flex -mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto overflow-y-hidden">
        <div className="flex flex-col flex-grow min-w-full shadow rounded-lg overflow-hidden">
          <div className="flex-grow overflow-y-auto">
            <table {...getTableProps()} className="min-w-full leading-normal">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="sticky top-0 z-20 px-5 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider"
                      >
                        {column.render("Header", {})}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SensorFirmwareTableRow
                      key={row.original.id}
                      onDownloadFirmware={downloadFirmware}
                      onDeleteFirmware={onDeleteFirmware}
                      row={row}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex-none flex mb-4">{footer && <>{footer}</>}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
