import * as Types from '../../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SetSensorGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  sensorGroup?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SetSensorGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateSensor?: Types.Maybe<(
    { __typename?: 'updateSensorPayload' }
    & { sensor?: Types.Maybe<(
      { __typename?: 'Sensor' }
      & Pick<Types.Sensor, 'id'>
      & { sensorGroup?: Types.Maybe<(
        { __typename?: 'SensorGroup' }
        & Pick<Types.SensorGroup, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateSensorGroupParentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  parentId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdateSensorGroupParentMutation = (
  { __typename?: 'Mutation' }
  & { updateASensorGroup?: Types.Maybe<(
    { __typename?: 'updateASensorGroupPayload' }
    & { sensorGroup?: Types.Maybe<(
      { __typename?: 'SensorGroup' }
      & Pick<Types.SensorGroup, 'id' | 'name'>
      & { parent?: Types.Maybe<(
        { __typename?: 'SensorGroup' }
        & Pick<Types.SensorGroup, 'id'>
      )> }
    )> }
  )> }
);


export const SetSensorGroupDocument = gql`
    mutation SetSensorGroup($id: ID!, $sensorGroup: String) {
  updateSensor(input: {id: $id, sensorGroup: $sensorGroup}) {
    sensor {
      id
      sensorGroup {
        id
        name
      }
    }
  }
}
    `;
export type SetSensorGroupMutationFn = Apollo.MutationFunction<SetSensorGroupMutation, SetSensorGroupMutationVariables>;

/**
 * __useSetSensorGroupMutation__
 *
 * To run a mutation, you first call `useSetSensorGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSensorGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSensorGroupMutation, { data, loading, error }] = useSetSensorGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sensorGroup: // value for 'sensorGroup'
 *   },
 * });
 */
export function useSetSensorGroupMutation(baseOptions?: Apollo.MutationHookOptions<SetSensorGroupMutation, SetSensorGroupMutationVariables>) {
        return Apollo.useMutation<SetSensorGroupMutation, SetSensorGroupMutationVariables>(SetSensorGroupDocument, baseOptions);
      }
export type SetSensorGroupMutationHookResult = ReturnType<typeof useSetSensorGroupMutation>;
export type SetSensorGroupMutationResult = Apollo.MutationResult<SetSensorGroupMutation>;
export type SetSensorGroupMutationOptions = Apollo.BaseMutationOptions<SetSensorGroupMutation, SetSensorGroupMutationVariables>;
export const UpdateSensorGroupParentDocument = gql`
    mutation UpdateSensorGroupParent($id: ID!, $parentId: String) {
  updateASensorGroup(input: {id: $id, parent: $parentId}) {
    sensorGroup {
      id
      name
      parent {
        id
      }
    }
  }
}
    `;
export type UpdateSensorGroupParentMutationFn = Apollo.MutationFunction<UpdateSensorGroupParentMutation, UpdateSensorGroupParentMutationVariables>;

/**
 * __useUpdateSensorGroupParentMutation__
 *
 * To run a mutation, you first call `useUpdateSensorGroupParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorGroupParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorGroupParentMutation, { data, loading, error }] = useUpdateSensorGroupParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUpdateSensorGroupParentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensorGroupParentMutation, UpdateSensorGroupParentMutationVariables>) {
        return Apollo.useMutation<UpdateSensorGroupParentMutation, UpdateSensorGroupParentMutationVariables>(UpdateSensorGroupParentDocument, baseOptions);
      }
export type UpdateSensorGroupParentMutationHookResult = ReturnType<typeof useUpdateSensorGroupParentMutation>;
export type UpdateSensorGroupParentMutationResult = Apollo.MutationResult<UpdateSensorGroupParentMutation>;
export type UpdateSensorGroupParentMutationOptions = Apollo.BaseMutationOptions<UpdateSensorGroupParentMutation, UpdateSensorGroupParentMutationVariables>;