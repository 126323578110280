import React from "react";
import { ActionButton } from "../../../component/ActionButton";

interface Props {
  selectionCount: number;
}

export const MultiSelectActionWidget: React.FunctionComponent<Props> = ({
  selectionCount,
  children,
}) => {
  if (!selectionCount) {
    return null;
  }

  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <div className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700">
        <span className="rounded-lg pt-1 px-2 text-white bg-primary-500">
          {selectionCount}
        </span>{" "}
        <span className="pt-1 ml-2">Sensors</span>
      </div>
      <span className="-ml-px relative block">
        <div
          className="pt-1 relative inline-flex items-center pb-2 pt-3 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
          aria-label="Expand"
        >
          <ActionButton>{children}</ActionButton>
        </div>
      </span>
    </span>
  );
};
