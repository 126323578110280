import React, { useState } from "react";
import dayjs from "dayjs";
import { FileDownloader } from "../../../util/FileDownloader";
import { useEnvironment } from "../../../core/environment";
import { Panel } from "../../../component/Panel";
import { Button } from "../../../component/Button";

interface Props {}

export const SupportBundleDownloadPanel: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { environment } = useEnvironment();
  const downloadSupportBundle = async () => {
    setLoading(true);

    const url = `${environment.baseUrl}/supportArchive`;
    const dateString = dayjs().format("YYYY-MM-DD_HH.mm.ss");
    const filename = `hagl-vpn-supportbundle.${dateString}.zip`;
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    const fileDownloader = new FileDownloader();
    await fileDownloader.downloadFile(filename, url, "POST", headers);

    setLoading(false);
  };

  return (
    <Panel>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
          Download support bundle
        </h3>
        <div className="text-sm leading-5 text-gray-700">
          <p>
            A support bundle contains all relevant log files and debug
            information. Collecting the data may take a moment.
          </p>
        </div>
        <div className="mt-5 flex justify-end">
          <Button
            disabled={loading}
            onClick={(event) => {
              event.preventDefault();
              downloadSupportBundle();
            }}
          >
            {loading ? "Preparing, please wait..." : "Download now"}
          </Button>
        </div>
      </div>
    </Panel>
  );
};
