import React, { useEffect, useState } from "react";
import { ModalDialog } from "../../../component/ModalDialog";
import { Button } from "../../../component/Button";

interface Props {
  show: boolean;
  onClose: () => void;
}

export const AddSensorDialog: React.FunctionComponent<Props> = ({
  show,
  onClose,
}) => {
  const [clientPassword, setClientPassword] = useState<string>();
  useEffect(() => {
    fetch("/control/api/serverConfiguration").then((response) => {
      response.json().then((body) => {
        setClientPassword(body.clientPassword);
      });
    });
  }, []);

  return (
    <ModalDialog show={show} onCancel={onClose}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900 px-2"
              id="modal-headline"
            >
              Add Sensor
            </h3>
            <div className="mt-4">
              <p className={"p-2"}>
                To add a new sensor to the APS Device Management, you need to
                enable the VPN Auto Connection on the sensor. To do so you need
                to go to the Network configuration in the HMI of the sensor. You
                need to specify the following settings:
              </p>
              <div className="mt-4 flex justify-between hover:bg-gray-100 px-2 items-center">
                <span className="font-bold">Enable VPN:</span>{" "}
                <input type="checkbox" checked />
              </div>
              <div className="mt-2 flex justify-between hover:bg-gray-100 px-2 items-center">
                <span className="font-bold">Use Control Server:</span>{" "}
                <input type="checkbox" checked />
              </div>
              <div className="mt-2 flex justify-between hover:bg-gray-100 px-2 items-center">
                <span className="font-bold">Control Server:</span>{" "}
                <span className="select-all">{window.location.hostname}</span>
              </div>
              <div className="mt-2 flex justify-between hover:bg-gray-100 px-2 items-center">
                <span className="font-bold">Control Server Port:</span>{" "}
                <span className="select-all">
                  {window.location.port || 443}
                </span>
              </div>
              <div className="mt-2 flex justify-between hover:bg-gray-100 px-2 items-center">
                <span className="font-bold">Control Server Path:</span>{" "}
                <span className="select-all">/api</span>
              </div>
              <div className="mt-2 flex justify-between hover:bg-gray-100 px-2 items-center">
                <span className="font-bold">Control Server Password:</span>{" "}
                <span className="select-all">
                  {clientPassword || "loading…"}
                </span>
              </div>
              <div className="mt-2 flex justify-between hover:bg-gray-100 px-2 items-center">
                <span className="font-bold">Cycle Time:</span>{" "}
                <span className="select-all">1</span>
              </div>
            </div>
            <div className="mt-2 flex justify-end pr-2">
              <Button onClick={onClose}>Close</Button>
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};
