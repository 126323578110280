import * as Types from '../../generated/graphql';

import { UserRowFragment } from '../../graphql/User.generated';
import { gql } from '@apollo/client';
import { UserRowFragmentDoc } from '../../graphql/User.generated';
import * as Apollo from '@apollo/client';
export type UsersQueryVariables = Types.Exact<{
  pageSize: Types.Scalars['Int'];
  after?: Types.Maybe<Types.Scalars['String']>;
  before?: Types.Maybe<Types.Scalars['String']>;
  username?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Types.Maybe<(
    { __typename?: 'UserConnection' }
    & Pick<Types.UserConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'UserPageInfo' }
      & Pick<Types.UserPageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'UserEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'User' }
        & UserRowFragment
      )> }
    )>>> }
  )> }
);


export const UsersDocument = gql`
    query Users($pageSize: Int!, $after: String, $before: String, $username: String) {
  users(first: $pageSize, after: $after, before: $before, username: $username) @connection(key: "users", filters: ["username", "pageSize"]) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...UserRow
      }
    }
  }
}
    ${UserRowFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;