import React, { useState } from "react";
import { ModalDialog } from "../../../../component/ModalDialog";
import { Button } from "../../../../component/Button";
import { ProgressBar } from "../../../../component/ProgressBar";

interface Props {
  show: boolean;
  onCancel: () => void;
  onConfirm: (name: string, file: File) => void;
  uploading: boolean;
  uploadPercentage: number;
}

export const SensorFirmwareUploadDialog: React.FunctionComponent<Props> = ({
  show,
  onCancel,
  onConfirm,
  uploading,
  uploadPercentage,
}) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  const [fileError, setFileError] = useState(false);

  const handleConfirmation = () => {
    setNameError(false);
    setFileError(false);

    const firmwareName = name.trim();
    const file = files ? files[0] : null;

    if (firmwareName === "") {
      setNameError(true);

      return;
    }

    if (file === null) {
      setFileError(true);

      return;
    }

    onConfirm(name, file);
    setName("");
    setFiles(null);
  };

  return (
    <ModalDialog show={show} onCancel={onCancel}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex-grow">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Upload New Firmware
            </h3>
            {!uploading ? (
              <>
                <div className="mt-2">
                  <label
                    htmlFor="file"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Firmware Archive
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <input
                        id="file"
                        type="file"
                        className={`form-input ${
                          nameError && "border-red-500"
                        } block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                        onChange={(event) => {
                          const files = event.target.files;

                          if (files) {
                            const filenameParts = files[0].name.split(".");
                            filenameParts.pop();
                            setName(filenameParts.join("."));
                          }

                          setFiles(files);
                        }}
                      />
                    </div>
                  </div>
                  {fileError && (
                    <p className="text-red-500">File is required</p>
                  )}
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Name
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex items-stretch flex-grow focus-within:z-10">
                      <input
                        name="file"
                        className={`form-input ${
                          nameError && "border-red-500"
                        } block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                  </div>
                  {nameError && <p className="text-red-500">Invalid name</p>}
                </div>
              </>
            ) : (
              <div className="mt-4">
                <ProgressBar percentage={uploadPercentage} />
              </div>
            )}
            <div className="mt-6">
              <Button disabled={uploading} onClick={handleConfirmation}>
                Upload Firmware
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};
