import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ConnectionLogsQueryVariables = Types.Exact<{
  deviceName: Types.Scalars['String'];
}>;


export type ConnectionLogsQuery = (
  { __typename?: 'Query' }
  & { connectionLogEntries?: Types.Maybe<(
    { __typename?: 'ConnectionLogEntryConnection' }
    & Pick<Types.ConnectionLogEntryConnection, 'totalCount'>
    & { edges?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'ConnectionLogEntryEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'ConnectionLogEntry' }
        & Pick<Types.ConnectionLogEntry, 'id' | 'timestamp' | 'statusCode' | 'method' | 'remoteAddress' | 'command'>
      )> }
    )>>>, pageInfo: (
      { __typename?: 'ConnectionLogEntryPageInfo' }
      & Pick<Types.ConnectionLogEntryPageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);


export const ConnectionLogsDocument = gql`
    query ConnectionLogs($deviceName: String!) {
  connectionLogEntries(last: 5, sensor: $deviceName) {
    edges {
      node {
        id
        timestamp
        statusCode
        method
        remoteAddress
        command
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useConnectionLogsQuery__
 *
 * To run a query within a React component, call `useConnectionLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionLogsQuery({
 *   variables: {
 *      deviceName: // value for 'deviceName'
 *   },
 * });
 */
export function useConnectionLogsQuery(baseOptions: Apollo.QueryHookOptions<ConnectionLogsQuery, ConnectionLogsQueryVariables>) {
        return Apollo.useQuery<ConnectionLogsQuery, ConnectionLogsQueryVariables>(ConnectionLogsDocument, baseOptions);
      }
export function useConnectionLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionLogsQuery, ConnectionLogsQueryVariables>) {
          return Apollo.useLazyQuery<ConnectionLogsQuery, ConnectionLogsQueryVariables>(ConnectionLogsDocument, baseOptions);
        }
export type ConnectionLogsQueryHookResult = ReturnType<typeof useConnectionLogsQuery>;
export type ConnectionLogsLazyQueryHookResult = ReturnType<typeof useConnectionLogsLazyQuery>;
export type ConnectionLogsQueryResult = Apollo.QueryResult<ConnectionLogsQuery, ConnectionLogsQueryVariables>;