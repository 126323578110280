import React from "react";
import { Row } from "react-table";
import { UnapprovedSensorFragment } from "../SensorApprovalScene.generated";

interface Props {
  row: Row<UnapprovedSensorFragment>;
  onApproveSensor: (id: string) => void;
}

export const SensorApprovalTableRow: React.FunctionComponent<Props> = ({
  row,
  onApproveSensor,
}) => {
  return (
    <tr {...row.getRowProps()} className="bg-white even:bg-gray-100">
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} className="px-5 py-2 text-sm">
            {cell.render("Cell", {
              onApprove: onApproveSensor,
            })}
          </td>
        );
      })}
    </tr>
  );
};
