import React from "react";
import { ConnectionLogTable } from "../component/ConnectionLogTable";
import { gql } from "@apollo/client";
import { useConnectionLogsQuery } from "./SensorConnectionLogContainer.generated";
import { nodesFromCollectionQuery } from "../../../helper/nodesFromCollectionQuery";

interface Props {
  deviceName: string;
}

export const connectionLogQuery = gql`
  query ConnectionLogs($deviceName: String!) {
    connectionLogEntries(last: 5, sensor: $deviceName) {
      edges {
        node {
          id
          timestamp
          statusCode
          method
          remoteAddress
          command
        }
      }

      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const SensorConnectionLogContainer: React.FunctionComponent<Props> = ({
  deviceName,
}) => {
  const { data } = useConnectionLogsQuery({
    variables: { deviceName },
  });

  const connectionLogEntries = nodesFromCollectionQuery(
    data?.connectionLogEntries
  );

  return <ConnectionLogTable connectionLogEntries={connectionLogEntries} />;
};
