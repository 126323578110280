import * as Types from '../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SensorFirmwaresQueryVariables = Types.Exact<{
  pageSize: Types.Scalars['Int'];
  after?: Types.Maybe<Types.Scalars['String']>;
  before?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SensorFirmwaresQuery = (
  { __typename?: 'Query' }
  & { sensorFirmwares?: Types.Maybe<(
    { __typename?: 'SensorFirmwareConnection' }
    & Pick<Types.SensorFirmwareConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'SensorFirmwarePageInfo' }
      & Pick<Types.SensorFirmwarePageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'SensorFirmwareEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'SensorFirmware' }
        & Pick<Types.SensorFirmware, 'id' | 'name' | 'contentUrl'>
      )> }
    )>>> }
  )> }
);

export type UploadFirmwareMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  file: Types.Scalars['Upload'];
}>;


export type UploadFirmwareMutation = (
  { __typename?: 'Mutation' }
  & { uploadSensorFirmware?: Types.Maybe<(
    { __typename?: 'uploadSensorFirmwarePayload' }
    & { sensorFirmware?: Types.Maybe<(
      { __typename?: 'SensorFirmware' }
      & Pick<Types.SensorFirmware, 'id' | 'name' | 'contentUrl'>
    )> }
  )> }
);

export type DeleteFirmwareMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteFirmwareMutation = (
  { __typename?: 'Mutation' }
  & { deleteSensorFirmware?: Types.Maybe<(
    { __typename?: 'deleteSensorFirmwarePayload' }
    & { sensorFirmware?: Types.Maybe<(
      { __typename?: 'SensorFirmware' }
      & Pick<Types.SensorFirmware, 'id'>
    )> }
  )> }
);


export const SensorFirmwaresDocument = gql`
    query SensorFirmwares($pageSize: Int!, $after: String, $before: String) {
  sensorFirmwares(first: $pageSize, after: $after, before: $before) @connection(key: "sensorFirmwares", filters: ["name", "pageSize"]) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        id
        name
        contentUrl
      }
    }
  }
}
    `;

/**
 * __useSensorFirmwaresQuery__
 *
 * To run a query within a React component, call `useSensorFirmwaresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorFirmwaresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorFirmwaresQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSensorFirmwaresQuery(baseOptions: Apollo.QueryHookOptions<SensorFirmwaresQuery, SensorFirmwaresQueryVariables>) {
        return Apollo.useQuery<SensorFirmwaresQuery, SensorFirmwaresQueryVariables>(SensorFirmwaresDocument, baseOptions);
      }
export function useSensorFirmwaresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorFirmwaresQuery, SensorFirmwaresQueryVariables>) {
          return Apollo.useLazyQuery<SensorFirmwaresQuery, SensorFirmwaresQueryVariables>(SensorFirmwaresDocument, baseOptions);
        }
export type SensorFirmwaresQueryHookResult = ReturnType<typeof useSensorFirmwaresQuery>;
export type SensorFirmwaresLazyQueryHookResult = ReturnType<typeof useSensorFirmwaresLazyQuery>;
export type SensorFirmwaresQueryResult = Apollo.QueryResult<SensorFirmwaresQuery, SensorFirmwaresQueryVariables>;
export const UploadFirmwareDocument = gql`
    mutation UploadFirmware($name: String!, $file: Upload!) {
  uploadSensorFirmware(input: {name: $name, file: $file}) {
    sensorFirmware {
      id
      name
      contentUrl
    }
  }
}
    `;
export type UploadFirmwareMutationFn = Apollo.MutationFunction<UploadFirmwareMutation, UploadFirmwareMutationVariables>;

/**
 * __useUploadFirmwareMutation__
 *
 * To run a mutation, you first call `useUploadFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFirmwareMutation, { data, loading, error }] = useUploadFirmwareMutation({
 *   variables: {
 *      name: // value for 'name'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFirmwareMutation(baseOptions?: Apollo.MutationHookOptions<UploadFirmwareMutation, UploadFirmwareMutationVariables>) {
        return Apollo.useMutation<UploadFirmwareMutation, UploadFirmwareMutationVariables>(UploadFirmwareDocument, baseOptions);
      }
export type UploadFirmwareMutationHookResult = ReturnType<typeof useUploadFirmwareMutation>;
export type UploadFirmwareMutationResult = Apollo.MutationResult<UploadFirmwareMutation>;
export type UploadFirmwareMutationOptions = Apollo.BaseMutationOptions<UploadFirmwareMutation, UploadFirmwareMutationVariables>;
export const DeleteFirmwareDocument = gql`
    mutation DeleteFirmware($id: ID!) {
  deleteSensorFirmware(input: {id: $id}) {
    sensorFirmware {
      id
    }
  }
}
    `;
export type DeleteFirmwareMutationFn = Apollo.MutationFunction<DeleteFirmwareMutation, DeleteFirmwareMutationVariables>;

/**
 * __useDeleteFirmwareMutation__
 *
 * To run a mutation, you first call `useDeleteFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFirmwareMutation, { data, loading, error }] = useDeleteFirmwareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFirmwareMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFirmwareMutation, DeleteFirmwareMutationVariables>) {
        return Apollo.useMutation<DeleteFirmwareMutation, DeleteFirmwareMutationVariables>(DeleteFirmwareDocument, baseOptions);
      }
export type DeleteFirmwareMutationHookResult = ReturnType<typeof useDeleteFirmwareMutation>;
export type DeleteFirmwareMutationResult = Apollo.MutationResult<DeleteFirmwareMutation>;
export type DeleteFirmwareMutationOptions = Apollo.BaseMutationOptions<DeleteFirmwareMutation, DeleteFirmwareMutationVariables>;