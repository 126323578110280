import React, { useEffect, useRef } from "react";
import Transition from "../util/Transition";
import { Button } from "./Button";

interface Props {
  show: boolean;
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmationDialog: React.FunctionComponent<Props> = ({
  show,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}) => {
  const modalBackdropRef = useRef<HTMLDivElement>(null);
  const modalWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalWindow = modalWindowRef.current;

    show && modalWindow && modalWindow.focus();
  }, [show]);

  return (
    <div className="z-30 fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center invisible">
      <Transition
        show={show}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        nodeRef={modalBackdropRef}
      >
        <div
          className="fixed inset-0 transition-opacity visible"
          onClick={() => onCancel()}
          ref={modalBackdropRef}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </Transition>
      <Transition
        show={show}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        nodeRef={modalWindowRef}
      >
        <div
          className="z-40 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full visible outline-none"
          role="dialog"
          ref={modalWindowRef}
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabIndex={-1}
          onKeyDown={(event) => event.key === "Escape" && onCancel()}
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-500">{message}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <Button
                buttonStyle="danger"
                onClick={(event) => {
                  event.preventDefault();
                  onConfirm();
                }}
              >
                {confirmButtonText}
              </Button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <Button
                buttonStyle="neutral"
                onClick={(event) => {
                  event.preventDefault();
                  onCancel();
                }}
              >
                {cancelButtonText}
              </Button>
            </span>
          </div>
        </div>
      </Transition>
    </div>
  );
};
