import React from "react";
import { useUpdateSensorGroupParentMutation } from "../SensorOverviewScene/SensorGroupTree/SensorGroupTreeNode.generated";
import { useDrop } from "../../../../hook/dragAndDrop/useDrop";

interface Props {}

interface DropCollectedProps {
  isOver: boolean;
  isOverCurrent: boolean;
}

export const SensorGroupTreeDroppableContainer: React.FunctionComponent<Props> = ({
  children,
}) => {
  const [updateSensorGroupParent] = useUpdateSensorGroupParentMutation({
    refetchQueries: ["Sensors"],
  });
  const [{ isOver, isOverCurrent }, drop] = useDrop<DropCollectedProps>({
    accept: ["SENSOR_GROUP"],
    drop: (item, monitor) => {
      if (item.type === "SENSOR_GROUP" && isOver) {
        updateSensorGroupParent({
          variables: {
            id: item.sensorGroupId,
            parentId: null,
          },
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <div
      ref={drop}
      className={`flex-1 flex flex-col overflow-y-auto pt-4 border-4 ${
        isOverCurrent ? "border-primary-400" : "border-transparent"
      }`}
    >
      {children}
    </div>
  );
};
