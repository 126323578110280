import React from "react";
import {
  SensorDetailDocument,
  SensorDetailFragment,
} from "../../../graphql/Sensor.generated";
import { useSensorGroups } from "../hook/useSensorGroups";
import { useSetSensorGroupMutation } from "../SensorOverview/SensorOverviewScene/SensorGroupTree/SensorGroupTreeNode.generated";
import {
  SearchableSelectInput,
  SelectOption,
} from "../../../component/Form/SearchableSelectInput";

interface Props {
  sensor: SensorDetailFragment;
}

export const SensorGroupAssignment: React.FunctionComponent<Props> = ({
  sensor,
}) => {
  const { sensorGroups } = useSensorGroups();
  const [setSensorGroup] = useSetSensorGroupMutation({
    refetchQueries: [
      "Sensors",
      {
        query: SensorDetailDocument,
        variables: { id: sensor.id },
      },
    ],
  });

  const sensorGroupOptions: SelectOption[] = sensorGroups.map(
    (sensorGroup) => ({
      value: sensorGroup.id === "" ? null : sensorGroup.id,
      label: sensorGroup.name,
    })
  );

  const selectedGroup =
    sensorGroupOptions.find((sensorGroupOption) => {
      if (sensor.sensorGroup === null) {
        return sensorGroupOption.value === null;
      }

      return sensorGroupOption.value === sensor.sensorGroup?.id;
    }) || null;

  const updateSensorGroup = (selectedOption: SelectOption) => {
    setSensorGroup({
      variables: {
        id: sensor.id,
        sensorGroup: selectedOption.value,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateSensor: {
          sensor: {
            __typename: "Sensor",
            id: sensor.id,
            sensorGroup:
              selectedOption.value === null
                ? null
                : {
                    __typename: "SensorGroup",
                    id: selectedOption.value,
                    name: selectedOption.label,
                  },
          },
        },
      },
    });
  };

  return (
    <SearchableSelectInput
      options={sensorGroupOptions}
      value={selectedGroup}
      onChange={updateSensorGroup}
    />
  );
};
