import React, { useState, useEffect, useRef } from "react";
import { SupportBundleDownloadPanel } from "./component/SupportBundleDownloadPanel";
import { CreateControlClientPanel } from "./component/CreateControlClientPanel";
import { gql } from "@apollo/client";
import {
  useApplicationSettingsQuery,
  useCreateSensorMutation,
  useUpdateApplicationSettingsMutation,
} from "./AdminScene.generated";
import {
  SuccessAlert,
  SuccessAlertDuration,
} from "../../component/SuccessAlert";
import { SettingsPanel } from "./component/SettingsPanel";
import { BackupApplicationSettingsFragment } from "../../graphql/ApplicationSettings.generated";

interface Props {}

export const CREATE_SENSOR = gql`
  mutation createSensor($deviceName: String!, $type: String!) {
    createSensor(input: { deviceName: $deviceName, type: $type }) {
      sensor {
        deviceName
        type
      }
    }
  }
`;

export const GET_APPLICATION_SETTINGS = gql`
  query applicationSettings($id: ID!) {
    applicationSettings(id: $id) {
      ...BackupApplicationSettings
    }
  }
`;

export const UPDATE_APPLICATION_SETTINGS = gql`
  mutation updateApplicationSettings(
    $id: ID!
    $backupRetentionCount: Int
    $backupIntervalLength: Int
    $backupIntervalUnit: String
  ) {
    updateApplicationSettings(
      input: {
        id: $id
        backupIntervalLength: $backupIntervalLength
        backupIntervalUnit: $backupIntervalUnit
        backupRetentionCount: $backupRetentionCount
      }
    ) {
      applicationSettings {
        ...BackupApplicationSettings
      }
    }
  }
`;

export const AdminScene: React.FunctionComponent<Props> = (props: Props) => {
  const createSensorFeedbackTimer = useRef<number | null>(null);
  const [createSensorAlertDisplayed, setCreateSensorAlertDisplayed] = useState(
    false
  );
  const [createSensor, { error: queryError }] = useCreateSensorMutation();
  const applicationSettingsId = "application_settings/application";
  const { data } = useApplicationSettingsQuery({
    variables: {
      id: applicationSettingsId,
    },
  });
  const [updateApplicationSettings] = useUpdateApplicationSettingsMutation();
  const addControlClient = (hostname: string) => {
    createSensor({
      variables: { deviceName: hostname, type: "control" },
    })
      .then(() => {
        setCreateSensorAlertDisplayed(true);
        createSensorFeedbackTimer.current = window.setTimeout(() => {
          setCreateSensorAlertDisplayed(false);
        }, SuccessAlertDuration.ALERT_DURATION_MEDIUM);
      })
      .catch(() => {
        // Error will be forwarded to the panel
      });
  };
  const saveBackupSettings = (settings: BackupApplicationSettingsFragment) => {
    updateApplicationSettings({
      variables: { id: applicationSettingsId, ...settings },
    });
  };

  // Release timeout on component unmount
  useEffect(
    () => () => {
      if (createSensorFeedbackTimer.current) {
        window.clearTimeout(createSensorFeedbackTimer.current);
      }
    },
    []
  );

  return (
    <React.Fragment>
      <SuccessAlert
        show={createSensorAlertDisplayed}
        message={"Control client created"}
        onDismiss={() => {
          setCreateSensorAlertDisplayed(false);
        }}
      />
      <div className="flex mb-4 sm:justify-stretch lg:justify-center flex-col lg:flex-row">
        <div className="lg:w-1/3">
          <CreateControlClientPanel
            errorMessages={queryError?.graphQLErrors?.map(
              ({ message }) => message
            )}
            onControlClientAdded={(hostname) => addControlClient(hostname)}
          />
          <SupportBundleDownloadPanel />
        </div>
        <div className="lg:w-1/3">
          {data?.applicationSettings && (
            <SettingsPanel
              applicationSettings={data.applicationSettings}
              onSettingsSaved={saveBackupSettings}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
