import React from "react";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DndProvider as ReactDndProvider,
  MouseTransition,
  TouchTransition,
} from "react-dnd-multi-backend";

const HTML5ToTouch = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      preview: true,
      options: {
        delayTouchStart: 350,
      },
      transition: TouchTransition,
      skipDispatchOnTransition: true,
    },
  ],
};

export const DndProvider: React.FunctionComponent = ({ children }) => {
  // I *think* that some typings are wrong here, becaouse the above HTML5ToTouch thing is mostly copy & pasted from the docs.
  // Even with the exact example from the docs it's breaking.
  // @ts-expect-error
  return <ReactDndProvider options={HTML5ToTouch}>{children}</ReactDndProvider>;
};
