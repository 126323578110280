import React from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  highlight?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

export const MobileNavLink: React.FunctionComponent<Props> = ({
  to,
  highlight,
  children,
  onClick,
}) => {
  return (
    <div className="block relative">
      {highlight && (
        <span className="inline-block h-3 w-3 mr-2 rounded-full text-white shadow-solid bg-red-500"></span>
      )}
      <Link
        to={to}
        onClick={onClick}
        className="inline-block mt-4 lg:inline-block lg:mt-0 text-primary-200 hover:text-white mr-4"
      >
        {children}
      </Link>
    </div>
  );
};
