import * as Types from '../generated/graphql';

import { UserDataFragment } from '../graphql/User.generated';
import { gql } from '@apollo/client';
import { UserDataFragmentDoc } from '../graphql/User.generated';
import * as Apollo from '@apollo/client';
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Types.Maybe<(
    { __typename?: 'User' }
    & UserDataFragment
  )> }
);

export type UnapprovedSensorsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UnapprovedSensorsCountQuery = (
  { __typename?: 'Query' }
  & { sensors?: Types.Maybe<(
    { __typename?: 'SensorConnection' }
    & Pick<Types.SensorConnection, 'totalCount'>
  )>, getApplicationInformation?: Types.Maybe<(
    { __typename?: 'ApplicationInformation' }
    & Pick<Types.ApplicationInformation, 'automaticSensorApprovalEnabled'>
  )> }
);


export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...UserData
  }
}
    ${UserDataFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UnapprovedSensorsCountDocument = gql`
    query UnapprovedSensorsCount {
  sensors(approved: false) {
    totalCount
  }
  getApplicationInformation {
    automaticSensorApprovalEnabled
  }
}
    `;

/**
 * __useUnapprovedSensorsCountQuery__
 *
 * To run a query within a React component, call `useUnapprovedSensorsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnapprovedSensorsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnapprovedSensorsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnapprovedSensorsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnapprovedSensorsCountQuery, UnapprovedSensorsCountQueryVariables>) {
        return Apollo.useQuery<UnapprovedSensorsCountQuery, UnapprovedSensorsCountQueryVariables>(UnapprovedSensorsCountDocument, baseOptions);
      }
export function useUnapprovedSensorsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnapprovedSensorsCountQuery, UnapprovedSensorsCountQueryVariables>) {
          return Apollo.useLazyQuery<UnapprovedSensorsCountQuery, UnapprovedSensorsCountQueryVariables>(UnapprovedSensorsCountDocument, baseOptions);
        }
export type UnapprovedSensorsCountQueryHookResult = ReturnType<typeof useUnapprovedSensorsCountQuery>;
export type UnapprovedSensorsCountLazyQueryHookResult = ReturnType<typeof useUnapprovedSensorsCountLazyQuery>;
export type UnapprovedSensorsCountQueryResult = Apollo.QueryResult<UnapprovedSensorsCountQuery, UnapprovedSensorsCountQueryVariables>;