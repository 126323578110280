import React from "react";

interface Props {
  onClick: () => void;
  isPolling: boolean;
}

export const LiveReloadIndicator: React.FunctionComponent<Props> = ({
  onClick,
  isPolling,
}) => {
  return (
    <div
      title="Toggle live reload"
      onClick={onClick}
      className={`live-loading-indicator cursor-pointer ${
        isPolling ? "bg-tradewind-400 pulse" : "bg-gray-500"
      }`}
    />
  );
};
