import React, { useState } from "react";
import { Explanation } from "./component/TwoFactorSetup/Explanation";
import { SetupQrCode } from "./component/TwoFactorSetup/SetupQrCode";
import { VerifyCode } from "./component/TwoFactorSetup/VerifyCode";
import { useEnvironment } from "../../core/environment";
import { Redirect, useHistory } from "react-router";
import { Success } from "./component/TwoFactorSetup/Success";

export type Credentials = {
  username: string;
  password: string;
};

interface Props {
  credentials: Credentials | null;
}

export const SetupTwoFactorAuthScene: React.FunctionComponent<Props> = ({
  credentials,
}) => {
  const [sawExplanation, setSawExplanation] = useState(false);
  const [secret, setSecret] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { environment } = useEnvironment();
  const history = useHistory();

  if (!credentials) {
    return <Redirect to="/login" />;
  }

  const { password, username } = credentials;

  const handleVerifyToken = async (code: number) => {
    const response = await fetch(environment.baseUrl + "/auth/2fa_setup", {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        secret,
        code,
      }),
    });

    const responseData = await response.json();

    if (response.status === 200) {
      setSuccess(true);
      setErrorMessage("");
    }

    if (response.status === 401) {
      setErrorMessage(responseData.reason);
    }
  };

  if (success) {
    return (
      <Success
        onNext={() => {
          history.replace("/");
        }}
      />
    );
  }

  if (!sawExplanation) {
    return <Explanation onNext={() => setSawExplanation(true)} />;
  }

  if (secret) {
    return (
      <VerifyCode errorMessage={errorMessage} verifyToken={handleVerifyToken} />
    );
  }

  return <SetupQrCode onNext={setSecret} />;
};
