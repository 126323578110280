import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import { SensorDetailScene } from "./SensorDetails/SensorDetailScene";
import SensorOverviewScene from "./SensorOverview/SensorOverviewScene";
import { SensorApprovalScene } from "./SensorApproval/SensorApprovalScene";

interface Props {}

export const SensorScene: React.FunctionComponent<Props> = () => {
  return (
    <Switch>
      <Route path="/sensorApproval">
        <SensorApprovalScene />
      </Route>
      <Route path="/sensor/:sensorId">
        <SensorDetailScene />
      </Route>
      <Route path="/sensor">
        <SensorOverviewScene />
      </Route>
    </Switch>
  );
};
